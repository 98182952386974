
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'default' | 'white';
  className?: string;
}

const Logo = ({ size = 'md', variant = 'default', className = '' }: LogoProps) => {
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);
  
  const dimensions = {
    sm: 'h-6',
    md: 'h-8',
    lg: 'h-10',
  };
  
  const logoSrc = "/lovable-uploads/6c6348bd-8dd3-4094-bfd6-03421d3b3553.png";
  const imgClasses = `${dimensions[size]} ${variant === 'white' ? 'brightness-0 invert' : ''}`;
  
  return (
    <Link 
      to="/" 
      className={`flex items-center transition-opacity duration-500 ${mounted ? 'opacity-100' : 'opacity-0'} ${className}`}
      aria-label="TheTshirtMart home"
    >
      <img 
        src={logoSrc} 
        alt="TheTshirtMart Logo" 
        className={imgClasses}
      />
    </Link>
  );
};

export default Logo;
