
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface ProductCardProps {
  id: string;
  title: string;
  price: number;
  imageUrl: string;
  isNew?: boolean;
  isLimited?: boolean;
  className?: string;
}

const ProductCard = ({
  id,
  title,
  price,
  imageUrl,
  isNew = false,
  isLimited = false,
  className,
}: ProductCardProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  
  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setImageLoaded(true);
  }, [imageUrl]);
  
  return (
    <Link
      to={`/products/${id}`}
      className={cn(
        'group relative flex flex-col overflow-hidden rounded-lg bg-white shadow-md transition-all duration-300 hover-lift',
        className
      )}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="relative aspect-square w-full overflow-hidden bg-gray-100">
        {!imageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-orange border-t-transparent" />
          </div>
        )}
        <img
          src={imageUrl}
          alt={title}
          className={cn(
            'h-full w-full object-cover object-center transition-all duration-300',
            imageLoaded ? 'opacity-100' : 'opacity-0',
            isHovering ? 'scale-105' : 'scale-100'
          )}
        />
        
        {/* Badges */}
        <div className="absolute top-2 left-2 flex flex-col gap-2">
          {isNew && (
            <span className="inline-flex h-6 items-center rounded-full bg-brand-orange px-2.5 text-xs font-medium text-white">
              New
            </span>
          )}
          {isLimited && (
            <span className="inline-flex h-6 items-center rounded-full bg-black px-2.5 text-xs font-medium text-white">
              Limited
            </span>
          )}
        </div>
      </div>
      
      <div className="flex flex-1 flex-col p-4">
        <h3 className="text-sm font-medium text-gray-900 group-hover:text-brand-orange transition-colors">
          {title}
        </h3>
        <div className="mt-1 flex items-center justify-between">
          <p className="text-sm font-semibold text-brand-black">
            ${price.toFixed(2)}
          </p>
          <span className="relative overflow-hidden rounded-full p-1 transition-colors group-hover:bg-brand-orange/10">
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              className="h-5 w-5 transition-transform group-hover:translate-x-1"
            >
              <path 
                d="M14 5l7 7m0 0l-7 7m7-7H3" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                className="text-gray-400 group-hover:text-brand-orange"
              />
            </svg>
          </span>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
