
import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import Container from '../ui-custom/Container';
import Button from '../ui-custom/Button';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const LimitedDropSection = () => {
  const [visible, setVisible] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 2,
    hours: 14,
    minutes: 32,
    seconds: 10
  });
  
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('limited-drop');
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight * 0.75;
        setVisible(isVisible);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial state
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // Countdown timer simulation
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(prev => {
        if (prev.seconds > 0) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else if (prev.minutes > 0) {
          return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
        } else if (prev.hours > 0) {
          return { ...prev, hours: prev.hours - 1, minutes: 59, seconds: 59 };
        } else if (prev.days > 0) {
          return { ...prev, days: prev.days - 1, hours: 23, minutes: 59, seconds: 59 };
        }
        return prev;
      });
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);
  
  return (
    <section id="limited-drop" className="py-20 bg-brand-black text-white overflow-hidden">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left side - Image */}
          <div className={cn(
            "transition-all duration-1000",
            visible ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-12"
          )}>
            <div className="relative">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-brand-orange to-amber-500 rounded-xl blur opacity-50"></div>
              <div className="relative bg-brand-black rounded-xl overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1554568218-0f1715e72254?q=80&w=1887&auto=format&fit=crop" 
                  alt="Limited Edition Graphic Tee" 
                  className="w-full h-full object-cover hover:scale-105 transition-transform duration-700 ease-out"
                />
              </div>
            </div>
          </div>
          
          {/* Right side - Content */}
          <div className={cn(
            "transition-all duration-1000 delay-300",
            visible ? "opacity-100 translate-x-0" : "opacity-0 translate-x-12"
          )}>
            <div className="inline-block px-3 py-1 rounded-full bg-brand-orange/20 backdrop-blur-sm border border-brand-orange/30 mb-6">
              <p className="text-xs font-medium text-brand-orange">
                Limited Edition Drop
              </p>
            </div>
            
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              The "Abstract Geometry" Collection
            </h2>
            
            <p className="text-gray-400 mb-8">
              This exclusive drop features only 50 shirts worldwide. Inspired by modern architectural forms and abstract geometry, these designs represent precision and bold creativity.
            </p>
            
            {/* Countdown timer */}
            <div className="mb-8">
              <p className="text-sm text-gray-400 mb-2">Remaining until sold out:</p>
              <div className="grid grid-cols-4 gap-2">
                <div className="flex flex-col items-center p-3 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10">
                  <span className="text-2xl font-bold">{timeRemaining.days}</span>
                  <span className="text-xs text-gray-400">Days</span>
                </div>
                <div className="flex flex-col items-center p-3 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10">
                  <span className="text-2xl font-bold">{timeRemaining.hours}</span>
                  <span className="text-xs text-gray-400">Hours</span>
                </div>
                <div className="flex flex-col items-center p-3 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10">
                  <span className="text-2xl font-bold">{timeRemaining.minutes}</span>
                  <span className="text-xs text-gray-400">Minutes</span>
                </div>
                <div className="flex flex-col items-center p-3 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10">
                  <span className="text-2xl font-bold">{timeRemaining.seconds}</span>
                  <span className="text-xs text-gray-400">Seconds</span>
                </div>
              </div>
            </div>
            
            <div className="flex gap-4">
              <Button variant="premium" size="lg" as={Link} to="/limited-drops">
                Shop Now
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
              
              <Button 
                variant="outline" 
                size="lg" 
                as={Link} 
                to="/limited-drops"
                className="text-white border-white/20 hover:bg-white/5"
              >
                View Details
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LimitedDropSection;
