
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import TestimonialCard from '../components/testimonials/TestimonialCard';
import { cn } from '@/lib/utils';
import Button from '../components/ui-custom/Button';
import { Link } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { Star } from 'lucide-react';

// Mock testimonial data
const testimonials = [
  {
    id: '1',
    name: 'Alex Johnson',
    location: 'New York, NY',
    rating: 5,
    date: 'May 15, 2023',
    text: 'The quality of the fabric and print exceeded my expectations. This isn\'t your average t-shirt - the material is soft yet durable, and the design still looks amazing after several washes.',
    imageUrl: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=1587&auto=format&fit=crop',
    productImageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    productName: 'Urban Gradient Tee',
    verified: true,
  },
  {
    id: '2',
    name: 'Sarah Miller',
    location: 'Austin, TX',
    rating: 5,
    date: 'June 2, 2023',
    text: 'I love that these designs are limited edition! I\'ve gotten so many compliments on my shirt, and it feels special knowing not everyone will have the same one. Will definitely be watching for the next drop!',
    imageUrl: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1588&auto=format&fit=crop',
    productImageUrl: 'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
    productName: 'Minimalist Line Art',
    verified: true,
  },
  {
    id: '3',
    name: 'Michael Chen',
    location: 'Seattle, WA',
    rating: 4,
    date: 'July 10, 2023',
    text: 'The customization tool was super easy to use, and I was able to create exactly what I wanted. Shipping was fast too. Only giving 4 stars because the sizing runs slightly large.',
    productName: 'Custom Abstract Design',
    verified: true,
  },
  {
    id: '4',
    name: 'Emily Rodriguez',
    location: 'Miami, FL',
    rating: 5,
    date: 'August 5, 2023',
    text: 'This is now my go-to store for unique tees! The limited drops are always so creative, and I\'ve never seen designs like these anywhere else. Worth every penny for something truly unique.',
    imageUrl: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1561&auto=format&fit=crop',
    productImageUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?q=80&w=1780&auto=format&fit=crop',
    productName: 'Abstract Waves',
    verified: true,
  },
  {
    id: '5',
    name: 'James Wilson',
    location: 'Chicago, IL',
    rating: 5,
    date: 'September 12, 2023',
    text: 'Got a shirt from the newest drop and couldn\'t be happier. The design is even better in person than in the photos online. Already planning my next purchase!',
    imageUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1587&auto=format&fit=crop',
    verified: true,
  },
  {
    id: '6',
    name: 'Olivia Parker',
    location: 'Portland, OR',
    rating: 5,
    date: 'October 3, 2023',
    text: 'I appreciate that they use sustainable practices. The packaging was minimal and recyclable, and knowing my shirt was printed on-demand (not sitting in a warehouse) makes me feel better about my purchase.',
    productImageUrl: 'https://images.unsplash.com/photo-1503341504253-dff4815485f1?q=80&w=1770&auto=format&fit=crop',
    productName: 'Modern Typography',
    verified: true,
  },
];

const Testimonials = () => {
  const [visible, setVisible] = useState(false);
  const [reviewName, setReviewName] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [reviewRating, setReviewRating] = useState(5);
  const { toast } = useToast();
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  const handleSubmitReview = (e: React.FormEvent) => {
    e.preventDefault();
    
    // In a real app, you would send this to your backend
    toast({
      title: "Thank you for your review!",
      description: "Your feedback has been submitted and will be reviewed shortly.",
      variant: "default",
    });
    
    // Reset form
    setReviewName('');
    setReviewText('');
    setReviewRating(5);
  };
  
  return (
    <MainLayout>
      <section className="py-20 bg-brand-light-gray">
        <Container>
          <div className="text-center mb-16">
            <span className={cn(
              "inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-4 transition-opacity duration-700",
              visible ? "opacity-100" : "opacity-0"
            )}>
              <p className="text-xs font-medium text-brand-orange">
                Real Stories from Real Customers
              </p>
            </span>
            
            <h1 className={cn(
              "text-4xl md:text-5xl font-bold mb-6 transition-all duration-700",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              Customer Testimonials
            </h1>
            
            <p className={cn(
              "max-w-2xl mx-auto text-brand-dark-gray text-lg transition-all duration-700 delay-100",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              Don't just take our word for it. Here's what our community has to say about their TheTshirtMart experience.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
            {testimonials.map((testimonial, index) => (
              <div 
                key={testimonial.id}
                className={cn(
                  "transition-all duration-700",
                  visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12",
                  { 'delay-100': index % 3 === 0, 'delay-200': index % 3 === 1, 'delay-300': index % 3 === 2 }
                )}
              >
                <TestimonialCard {...testimonial} />
              </div>
            ))}
          </div>
          
          <div className={cn(
            "bg-white rounded-xl shadow-lg p-8 transition-all duration-700 delay-400",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12"
          )}>
            <h2 className="text-2xl font-bold mb-6 text-center">Share Your Experience</h2>
            
            <form onSubmit={handleSubmitReview} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Your Name
                </label>
                <input
                  id="name"
                  type="text"
                  value={reviewName}
                  onChange={(e) => setReviewName(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange outline-none transition-all"
                  placeholder="John Doe"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Rating
                </label>
                <div className="flex items-center gap-2">
                  {Array.from({ length: 5 }).map((_, i) => (
                    <button
                      key={i}
                      type="button"
                      onClick={() => setReviewRating(i + 1)}
                      className="focus:outline-none"
                    >
                      <Star
                        size={24}
                        className={cn(
                          "fill-current",
                          i < reviewRating ? "text-yellow-400" : "text-gray-300"
                        )}
                      />
                    </button>
                  ))}
                </div>
              </div>
              
              <div>
                <label htmlFor="review" className="block text-sm font-medium text-gray-700 mb-1">
                  Your Review
                </label>
                <textarea
                  id="review"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  required
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange outline-none transition-all resize-none"
                  placeholder="Tell us about your experience with our products..."
                />
              </div>
              
              <div className="pt-2">
                <Button type="submit" size="lg" className="w-full sm:w-auto">
                  Submit Review
                </Button>
              </div>
            </form>
          </div>
          
          <div className={cn(
            "mt-12 text-center transition-all duration-700 delay-500",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <p className="text-brand-dark-gray mb-4">
              Ready to experience our quality for yourself?
            </p>
            <Button as={Link} to="/shop" variant="default" size="lg">
              Shop Our Collections
            </Button>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default Testimonials;
