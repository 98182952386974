
import { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { cn } from '@/lib/utils';

interface TestimonialCardProps {
  name: string;
  location: string;
  rating: number;
  date: string;
  text: string;
  imageUrl?: string;
  productImageUrl?: string;
  productName?: string;
  verified?: boolean;
}

const TestimonialCard = ({
  name,
  location,
  rating,
  date,
  text,
  imageUrl,
  productImageUrl,
  productName,
  verified = false
}: TestimonialCardProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  
  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setIsImageLoaded(true);
    } else {
      setIsImageLoaded(true);
    }
  }, [imageUrl]);

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <div className="p-6">
        <div className="flex items-start gap-4">
          {imageUrl && (
            <div className="relative h-14 w-14 rounded-full overflow-hidden bg-gray-100 flex-shrink-0">
              {isImageLoaded ? (
                <img
                  src={imageUrl}
                  alt={name}
                  className="h-full w-full object-cover"
                />
              ) : (
                <div className="h-full w-full animate-pulse bg-gray-200" />
              )}
            </div>
          )}
          
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-medium text-brand-black">{name}</h3>
                <p className="text-sm text-gray-500">{location}</p>
              </div>
              {verified && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Verified Purchase
                </span>
              )}
            </div>
            
            <div className="flex items-center mt-1 mb-2">
              {Array.from({ length: 5 }).map((_, i) => (
                <Star
                  key={i}
                  size={16}
                  className={cn(
                    "fill-current",
                    i < rating ? "text-yellow-400" : "text-gray-300"
                  )}
                />
              ))}
              <span className="ml-2 text-xs text-gray-500">{date}</span>
            </div>
            
            <p className="text-gray-700 text-sm">{text}</p>
            
            {productImageUrl && productName && (
              <div className="mt-4 flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                <img
                  src={productImageUrl}
                  alt={productName}
                  className="h-16 w-16 object-cover rounded"
                />
                <span className="text-xs font-medium text-gray-700">
                  Purchased: {productName}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
