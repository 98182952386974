
import { useAuth } from '@/contexts/AuthContext';
import SignUpGate from './SignUpGate';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface AuthGateProps {
  children: React.ReactNode;
}

const AuthGate: React.FC<AuthGateProps> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();
  
  // Show auth banner only on specific pages
  const noAuthPromptPages = ["/404"];
  const needsAuthBanner = !isAuthenticated && !noAuthPromptPages.includes(location.pathname);
  
  // Show loading state if auth is still being determined
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-brand-orange"></div>
      </div>
    );
  }
  
  // Always show content for all users
  return (
    <>
      {needsAuthBanner && <SignUpGate />}
      {children}
    </>
  );
};

export default AuthGate;
