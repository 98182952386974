
interface UpdateCanvasParams {
  canvas: HTMLCanvasElement;
  customText: string;
  textColor: string;
  textSize: number;
  textFont: string;
  textRotation: number;
  uploadedImage: string | null;
  imageScale: number;
  activeTab: string;
}

export const updateCanvas = ({
  canvas,
  customText,
  textColor,
  textSize,
  textFont,
  textRotation,
  uploadedImage,
  imageScale,
  activeTab
}: UpdateCanvasParams) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  
  // Clear canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  
  // Draw t-shirt base
  const tshirtImage = document.createElement('img');
  tshirtImage.onload = () => {
    ctx.drawImage(tshirtImage, 0, 0, canvas.width, canvas.height);
    
    // Calculate text position (centered)
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2.5;
    
    // Draw custom text if any
    if (customText && activeTab === 'text') {
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(textRotation * Math.PI / 180);
      ctx.font = `${textSize}px ${textFont}`;
      ctx.fillStyle = textColor;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      // Draw text
      ctx.fillText(customText, 0, 0);
      ctx.restore();
    }
    
    // Draw uploaded image if any
    if (uploadedImage && activeTab === 'image') {
      const img = document.createElement('img');
      img.onload = () => {
        const scale = imageScale / 100;
        const imgWidth = img.width * scale;
        const imgHeight = img.height * scale;
        
        ctx.save();
        ctx.translate(centerX, centerY);
        ctx.rotate(textRotation * Math.PI / 180);
        ctx.drawImage(img, -imgWidth / 2, -imgHeight / 2, imgWidth, imgHeight);
        ctx.restore();
      };
      img.src = uploadedImage;
    }
  };
  
  // Placeholder for different colored t-shirts
  // In a real app, you would have different base images for each color
  tshirtImage.src = '/lovable-uploads/6ee030cf-ebc1-4fac-922d-e2abede81dd4.png';
};
