
import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';

interface ContactHeaderProps {
  visible: boolean;
}

const ContactHeader = ({ visible }: ContactHeaderProps) => {
  return (
    <div className="text-center mb-16">
      <span className={cn(
        "inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-4 transition-opacity duration-700",
        visible ? "opacity-100" : "opacity-0"
      )}>
        <p className="text-xs font-medium text-brand-orange">
          Get In Touch
        </p>
      </span>
      
      <h1 className={cn(
        "text-4xl md:text-5xl font-bold mb-6 transition-all duration-700",
        visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
      )}>
        Contact Us
      </h1>
      
      <p className={cn(
        "max-w-2xl mx-auto text-brand-dark-gray text-lg transition-all duration-700 delay-100",
        visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
      )}>
        Have a question, feedback, or need assistance? We're here to help! Fill out the form below or reach out through one of our contact channels.
      </p>
    </div>
  );
};

export default ContactHeader;
