
import { cn } from "@/lib/utils";
import React from "react";

interface ContainerProps {
  className?: string;
  children: React.ReactNode;
  as?: React.ElementType;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full";
}

const Container = ({
  className,
  children,
  as: Component = "div",
  maxWidth = "xl",
}: ContainerProps) => {
  return (
    <Component
      className={cn(
        "w-full mx-auto px-4 sm:px-6 lg:px-8",
        {
          "max-w-screen-sm": maxWidth === "xs",
          "max-w-screen-md": maxWidth === "sm",
          "max-w-screen-lg": maxWidth === "md",
          "max-w-screen-xl": maxWidth === "lg",
          "max-w-screen-2xl": maxWidth === "xl",
          "max-w-[1600px]": maxWidth === "2xl",
          "max-w-none": maxWidth === "full",
        },
        className
      )}
    >
      {children}
    </Component>
  );
};

export default Container;
