
import React from 'react';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline' | 'ghost' | 'link' | 'premium';
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
  children: React.ReactNode;
  as?: React.ElementType;
  to?: string; // For React Router Link support
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', size = 'md', isLoading = false, children, as, to, ...props }, ref) => {
    const [mounted, setMounted] = useState(false);
    
    useEffect(() => {
      setMounted(true);
    }, []);
    
    // Base class names that apply to all button variants
    const baseClasses = cn(
      'relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
      {
        'bg-brand-orange text-white hover:bg-brand-orange/90 shadow-md hover:shadow-lg': variant === 'default',
        'border border-brand-orange/20 bg-transparent text-brand-orange hover:bg-brand-orange/10': variant === 'outline',
        'bg-transparent text-foreground hover:bg-muted': variant === 'ghost',
        'bg-transparent text-brand-orange underline-offset-4 hover:underline': variant === 'link',
        'bg-gradient-to-r from-brand-orange to-amber-500 text-white shadow-md hover:shadow-lg hover:opacity-90': variant === 'premium',
        'h-9 px-3 py-2': size === 'sm',
        'h-10 px-4 py-2': size === 'md',
        'h-12 px-6 py-3 text-base': size === 'lg',
      },
      mounted ? 'animate-fade-in' : 'opacity-0',
      isLoading ? 'cursor-wait' : '',
      className
    );
    
    // Determine the component to render
    const Component = as || 'button';
    
    // Construct the appropriate props based on the component
    const componentProps: any = {
      className: baseClasses,
      disabled: isLoading || props.disabled,
      ...props
    };
    
    // Add ref only if Component is 'button'
    if (Component === 'button') {
      componentProps.ref = ref;
    }
    
    // Add to prop if Component is Link
    if (Component === Link && to) {
      componentProps.to = to;
    }
    
    return (
      <Component {...componentProps}>
        {isLoading ? (
          <span className="absolute inset-0 flex items-center justify-center">
            <svg 
              className="animate-spin h-5 w-5" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24"
            >
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
              />
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </span>
        ) : null}
        <span className={isLoading ? 'invisible' : ''}>{children}</span>
      </Component>
    );
  }
);

Button.displayName = 'Button';

export default Button;
