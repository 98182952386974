
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Palette, Shirt, Check } from 'lucide-react';

const MarketingSection = () => {
  return (
    <>
      {/* SEO and Marketing Content */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Palette className="mr-2 text-brand-orange" />
              Express Your Style
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              Our custom t-shirt design tool makes it easy to create clothing that reflects your personality. 
              Add text, upload images, and choose from premium quality fabrics in various colors.
            </p>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Shirt className="mr-2 text-brand-orange" />
              Premium Quality
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              All our customizable apparel is made from high-quality materials that are built to last. 
              Pre-shrunk fabric ensures your custom design stays looking great wash after wash.
            </p>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Check className="mr-2 text-brand-orange" />
              Satisfaction Guaranteed
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              Not happy with your custom design? We offer free returns within 30 days. 
              Our commitment to quality ensures you'll love your personalized clothing.
            </p>
          </CardContent>
        </Card>
      </div>
      
      {/* FAQ for SEO */}
      <div className="mt-16">
        <h2 className="text-3xl font-bold text-brand-black mb-8">Frequently Asked Questions</h2>
        
        <div className="space-y-6">
          <div>
            <h3 className="text-xl font-semibold mb-2">How long does it take to receive my custom t-shirt?</h3>
            <p className="text-gray-700">
              Custom t-shirts typically take 5-7 business days for production, plus shipping time. 
              For rush orders, please contact our customer service team.
            </p>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-2">What kind of files can I upload for my design?</h3>
            <p className="text-gray-700">
              Our customization tool accepts JPG, PNG, and GIF files up to 5MB in size. 
              For best results, use high-resolution images with transparent backgrounds.
            </p>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-2">Can I order custom t-shirts in bulk for my team or event?</h3>
            <p className="text-gray-700">
              Yes! We offer special pricing for bulk orders of 10 or more items. 
              Contact our team for a custom quote and personalized service for your group order.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingSection;
