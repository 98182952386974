
import MainLayout from '../components/layout/MainLayout';
import HeroSection from '../components/home/HeroSection';
import FeaturedProducts from '../components/home/FeaturedProducts';
import LimitedDropSection from '../components/home/LimitedDropSection';
import FeaturesSection from '../components/home/FeaturesSection';
import NewsletterSection from '../components/home/NewsletterSection';

const Index = () => {
  return (
    <MainLayout>
      <HeroSection />
      <FeaturedProducts />
      <LimitedDropSection />
      <FeaturesSection />
      <NewsletterSection />
    </MainLayout>
  );
};

export default Index;
