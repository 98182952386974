
import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/hooks/use-toast';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import Button from '@/components/ui-custom/Button';
import Logo from '@/components/ui-custom/Logo';
import { Mail } from 'lucide-react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const signUpSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email" }),
  subscribe: z.boolean().default(false)
});

type SignUpFormValues = z.infer<typeof signUpSchema>;

interface SignUpGateProps {
  forceSignUp?: boolean;
}

const SignUpGate = ({ forceSignUp = false }: SignUpGateProps) => {
  const { signUp, loading: authLoading } = useAuth();
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(true);

  const form = useForm<SignUpFormValues>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: "",
      subscribe: true
    }
  });

  const onSubmit = async (data: SignUpFormValues) => {
    try {
      // Sign up with Supabase (magic link)
      await signUp(data.email);
      
      if (data.subscribe) {
        // Here you would add the user to your newsletter list
        toast({
          title: "Newsletter Subscription",
          description: "You've been subscribed to our newsletter.",
          variant: "default",
        });
      }
      
      // Close dialog after submission
      setIsOpen(false);
    } catch (error) {
      // Error handling is done in the AuthContext
    }
  };

  const handleClose = () => {
    // Always allow closing the dialog
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-md">
        <div className="flex justify-center mb-4">
          <Logo />
        </div>
        
        <DialogHeader>
          <DialogTitle className="text-center text-2xl">Join Our Exclusive Community</DialogTitle>
          <DialogDescription className="text-center">
            Sign up to access our exclusive t-shirt designs and limited drops
          </DialogDescription>
        </DialogHeader>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 pt-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input 
                        placeholder="you@example.com" 
                        {...field} 
                        className="pr-10"
                      />
                      <Mail className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="subscribe"
                checked={form.watch("subscribe")}
                onChange={() => form.setValue("subscribe", !form.watch("subscribe"))}
                className="h-4 w-4 rounded border-gray-300 text-brand-orange focus:ring-brand-orange"
              />
              <label htmlFor="subscribe" className="text-sm text-gray-700">
                Subscribe to our newsletter for exclusive deals and drops
              </label>
            </div>
            
            <Button 
              type="submit" 
              className="w-full" 
              isLoading={authLoading}
            >
              Sign Up & Continue
            </Button>

            <div className="flex justify-between items-center mt-4">
              <button 
                type="button" 
                onClick={handleClose} 
                className="text-sm text-gray-500 hover:text-brand-orange transition-colors"
              >
                Continue as Guest
              </button>
              
              <p className="text-sm text-gray-600">
                Magic link, no password needed
              </p>
            </div>
          </form>
        </Form>
        
        <div className="text-xs text-center text-gray-500 mt-4">
          By signing up, you agree to our Terms of Service and Privacy Policy
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpGate;
