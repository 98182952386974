
import { useState } from 'react';
import Container from '../ui-custom/Container';
import Button from '../ui-custom/Button';
import { useToast } from '@/hooks/use-toast';

const NewsletterSection = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Simulate API call
    setTimeout(() => {
      toast({
        title: "Successfully subscribed!",
        description: "You'll be the first to know about our new drops.",
        variant: "default",
      });
      setEmail('');
      setIsSubmitting(false);
    }, 1000);
  };
  
  return (
    <section className="py-20 bg-brand-light-gray">
      <Container maxWidth="lg">
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-brand-black to-brand-dark-gray p-8 md:p-12">
          {/* Background Elements */}
          <div className="absolute -top-24 -right-24 w-64 h-64 rounded-full bg-brand-orange/20 blur-3xl" />
          <div className="absolute -bottom-24 -left-24 w-64 h-64 rounded-full bg-brand-orange/10 blur-3xl" />
          
          <div className="relative z-10 flex flex-col md:flex-row items-center justify-between gap-8">
            <div className="text-center md:text-left">
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-2">
                Stay in the Loop
              </h2>
              <p className="text-gray-400 max-w-md">
                Subscribe to our newsletter to be the first to know about new limited drops, exclusive offers, and more.
              </p>
            </div>
            
            <form onSubmit={handleSubmit} className="w-full md:w-auto">
              <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="px-4 py-3 rounded-lg bg-white/10 border border-white/20 text-white placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-orange/50 w-full md:w-80"
                />
                <Button 
                  type="submit" 
                  size="lg" 
                  isLoading={isSubmitting}
                >
                  Subscribe
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default NewsletterSection;
