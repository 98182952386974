
import { useEffect, useState } from 'react';
import MainLayout from '@/components/layout/MainLayout';
import Container from '@/components/ui-custom/Container';
import Button from '@/components/ui-custom/Button';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { ArrowRight, Palette, Users, Sparkles, BookCheck, Leaf } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';

const AboutUs = () => {
  const [visible, setVisible] = useState({
    hero: false,
    mission: false,
    features: false,
    vision: false,
    join: false,
  });

  useEffect(() => {
    // Set initial visibility
    setVisible({
      hero: true,
      mission: false,
      features: false,
      vision: false,
      join: false,
    });

    // Handle scroll animations
    const handleScroll = () => {
      const sections = [
        { id: 'mission', key: 'mission' },
        { id: 'features', key: 'features' },
        { id: 'vision', key: 'vision' },
        { id: 'join', key: 'join' },
      ];

      sections.forEach(({ id, key }) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const isVisible = rect.top < window.innerHeight * 0.8;
          setVisible(prev => ({ ...prev, [key]: isVisible }));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial state
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <MainLayout>
      {/* Hero Section */}
      <section className="py-20 bg-brand-black text-white">
        <Container maxWidth="xl">
          <div className={cn(
            "transition-all duration-1000 ease-in-out transform",
            visible.hero ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
              About Us
            </h1>
            <h2 className="text-2xl md:text-3xl font-semibold text-brand-orange mb-8">
              Where Creativity Meets Limited Edition Fashion
            </h2>
            <div className="max-w-3xl">
              <p className="text-lg text-gray-300 mb-8">
                Welcome to <span className="text-brand-orange font-semibold">TheTshirtMart.com</span>, the home of exclusive, limited-run designs that push the boundaries of creativity and self-expression. We don't just sell t-shirts—we craft wearable art that tells a story, sparks conversations, and stands out in a world of mass production.
              </p>
              <p className="text-lg text-gray-300">
                At TheTshirtMart, we believe that fashion is more than just fabric—it's a statement. That's why we specialize in <span className="text-white font-semibold">limited edition releases</span>, ensuring that every piece you own is as unique as you are. When you wear one of our designs, you're not just wearing a shirt—you're making a statement, embracing creativity, and joining a community that values originality.
              </p>
            </div>
          </div>
        </Container>
      </section>
      
      {/* What Makes Us Different Section */}
      <section id="features" className="py-16 bg-white">
        <Container maxWidth="xl">
          <div className={cn(
            "transition-all duration-1000 ease-in-out transform",
            visible.features ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
              What Makes Us Different?
            </h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <Card className="overflow-hidden border-none shadow-lg transition-transform duration-300 hover:scale-105">
                <div className="h-2 bg-brand-orange"></div>
                <CardContent className="pt-8">
                  <div className="mb-4 flex items-center justify-center w-12 h-12 rounded-full bg-brand-orange/10 text-brand-orange">
                    <Sparkles size={24} />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Limited Edition Drops</h3>
                  <p className="text-gray-600">
                    We don't mass-produce. Each design is released in exclusive small-batch collections, making our apparel rare, valuable, and one-of-a-kind. Once a drop is sold out, it's gone for good.
                  </p>
                </CardContent>
              </Card>
              
              <Card className="overflow-hidden border-none shadow-lg transition-transform duration-300 hover:scale-105">
                <div className="h-2 bg-brand-orange"></div>
                <CardContent className="pt-8">
                  <div className="mb-4 flex items-center justify-center w-12 h-12 rounded-full bg-brand-orange/10 text-brand-orange">
                    <Palette size={24} />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Creative Collaborations</h3>
                  <p className="text-gray-600">
                    We partner with influencers, artists, and trendsetters to bring you fresh, bold, and culturally inspired designs that you won't find anywhere else.
                  </p>
                </CardContent>
              </Card>
              
              <Card className="overflow-hidden border-none shadow-lg transition-transform duration-300 hover:scale-105">
                <div className="h-2 bg-brand-orange"></div>
                <CardContent className="pt-8">
                  <div className="mb-4 flex items-center justify-center w-12 h-12 rounded-full bg-brand-orange/10 text-brand-orange">
                    <Users size={24} />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Customization & Personalization</h3>
                  <p className="text-gray-600">
                    Want to make a design truly yours? Many of our collections allow for custom personalization, giving you the freedom to add your own touch.
                  </p>
                </CardContent>
              </Card>
              
              <Card className="overflow-hidden border-none shadow-lg transition-transform duration-300 hover:scale-105">
                <div className="h-2 bg-brand-orange"></div>
                <CardContent className="pt-8">
                  <div className="mb-4 flex items-center justify-center w-12 h-12 rounded-full bg-brand-orange/10 text-brand-orange">
                    <BookCheck size={24} />
                  </div>
                  <h3 className="text-xl font-bold mb-2">High-Quality Print-On-Demand</h3>
                  <p className="text-gray-600">
                    Powered by Printify, we work with top-tier print providers to ensure every design is printed with vibrant colors, premium fabric, and long-lasting durability.
                  </p>
                </CardContent>
              </Card>
              
              <Card className="overflow-hidden border-none shadow-lg transition-transform duration-300 hover:scale-105">
                <div className="h-2 bg-brand-orange"></div>
                <CardContent className="pt-8">
                  <div className="mb-4 flex items-center justify-center w-12 h-12 rounded-full bg-brand-orange/10 text-brand-orange">
                    <Leaf size={24} />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Sustainable & Ethical Practices</h3>
                  <p className="text-gray-600">
                    We embrace a print-on-demand model to reduce waste, overproduction, and our environmental footprint. No excess inventory—just on-demand, high-quality apparel made specifically for you.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </Container>
      </section>
      
      {/* Our Vision Section */}
      <section id="vision" className="py-16 bg-brand-light-gray">
        <Container maxWidth="xl">
          <div className={cn(
            "transition-all duration-1000 ease-in-out transform",
            visible.vision ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-8">
                Our Vision: Setting Trends, Not Following Them
              </h2>
              
              <p className="text-lg text-gray-700 mb-6">
                Our mission is simple: <span className="font-semibold">To inspire creativity, self-expression, and individuality through unique apparel.</span> We aim to lead, innovate, and set the trends that others follow.
              </p>
              
              <p className="text-lg text-gray-700 mb-6">
                At TheTshirtMart, we don't just create t-shirts—we create movements. Our designs reflect <span className="font-semibold">bold ideas, emerging trends, and cultural inspirations</span> that resonate with fashion-forward individuals, influencers, and niche communities.
              </p>
              
              <p className="text-lg text-gray-700">
                We want to be the brand that others look to for inspiration—the place where <span className="font-semibold">art meets fashion, and ideas become iconic.</span>
              </p>
            </div>
          </div>
        </Container>
      </section>
      
      {/* Join the Movement Section */}
      <section id="join" className="py-16 bg-brand-black text-white">
        <Container maxWidth="xl">
          <div className={cn(
            "transition-all duration-1000 ease-in-out transform",
            visible.join ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-8">
                Join the Movement
              </h2>
              
              <p className="text-lg text-gray-300 mb-10">
                When you shop with <span className="text-brand-orange font-semibold">TheTshirtMart</span>, you're not just buying a t-shirt—you're joining a <span className="text-white font-semibold">creative revolution</span>. Whether you're here to find an exclusive drop, collaborate with us, or customize your own masterpiece, we're excited to have you on this journey.
              </p>
              
              <div className="flex flex-col md:flex-row items-center justify-center gap-6 mb-12">
                <Button variant="premium" size="lg" as={Link} to="/shop">
                  Shop Now
                  <ArrowRight size={16} />
                </Button>
                
                <Button 
                  variant="outline" 
                  size="lg" 
                  as={Link} 
                  to="/contact"
                  className="text-white border-white/20 hover:bg-white/5"
                >
                  Collaborate With Us
                </Button>
              </div>
              
              <div className="flex flex-col gap-3 text-left">
                <p className="flex items-center gap-2">
                  <span className="text-brand-orange">🚀</span>
                  <span>Follow us on social media (@TheTshirtMart) to stay updated on the latest drops, behind-the-scenes content, and influencer collaborations.</span>
                </p>
                <p className="flex items-center gap-2">
                  <span className="text-brand-orange">🎨</span>
                  <span>Want to collaborate or feature your artwork? <Link to="/contact" className="text-brand-orange hover:underline">Contact us here</Link> – we're always looking for fresh creative minds to join the movement.</span>
                </p>
                <p className="flex items-center gap-2">
                  <span className="text-brand-orange">👕</span>
                  <span>Explore our latest collections and grab your exclusive piece before it's gone! <Link to="/shop" className="text-brand-orange hover:underline">Shop Now</Link></span>
                </p>
              </div>
              
              <p className="mt-12 text-xl font-semibold text-brand-orange">
                TheTshirtMart – Redefining Style, One Limited Edition at a Time.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default AboutUs;
