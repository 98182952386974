
import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import Container from '../ui-custom/Container';
import { Sparkles, Clock, Palette, RefreshCcw } from 'lucide-react';

const features = [
  {
    icon: <Sparkles className="h-6 w-6 text-brand-orange" />,
    title: 'Exclusive Designs',
    description: 'Our t-shirts feature unique artwork you won\'t find anywhere else, created by talented designers.',
  },
  {
    icon: <Clock className="h-6 w-6 text-brand-orange" />,
    title: 'Limited Editions',
    description: 'Each design is produced in limited quantities, ensuring exclusivity for our customers.',
  },
  {
    icon: <Palette className="h-6 w-6 text-brand-orange" />,
    title: 'Premium Quality',
    description: 'Made with high-quality fabrics and printing techniques for durability and comfort.',
  },
  {
    icon: <RefreshCcw className="h-6 w-6 text-brand-orange" />,
    title: 'New Drops Regularly',
    description: 'We release new designs every month, so there\'s always something fresh to discover.',
  },
];

const FeaturesSection = () => {
  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('features');
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight * 0.75;
        setVisible(isVisible);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial state
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <section id="features" className="py-20 bg-white overflow-hidden">
      <Container>
        <div className="text-center mb-16">
          <h2 className={cn(
            "text-3xl md:text-4xl font-bold mb-4 transition-all duration-700",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            Why Choose TheTshirtMart?
          </h2>
          <p className={cn(
            "max-w-2xl mx-auto text-brand-dark-gray text-lg transition-all duration-700 delay-100",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            We're not just another t-shirt store. We're a destination for exclusive, limited-run designs that stand out from the crowd.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className={cn(
                "bg-white p-6 rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-all duration-500",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12",
                { 'delay-100': index === 0, 'delay-200': index === 1, 'delay-300': index === 2, 'delay-400': index === 3 }
              )}
            >
              <div className="w-12 h-12 rounded-full bg-brand-orange/10 flex items-center justify-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-brand-gray">{feature.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default FeaturesSection;
