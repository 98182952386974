import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import { cn } from '@/lib/utils';
import { CalendarDays, User, Tag, Share2, Heart, Facebook, Twitter, Linkedin, Copy } from 'lucide-react';
import Button from '../components/ui-custom/Button';
import BlogCard from '../components/blog/BlogCard';
import { useToast } from '@/hooks/use-toast';

// Mock blog post content
const blogPost = {
  slug: 'the-art-of-t-shirt-design',
  title: 'The Art of T-Shirt Design: From Concept to Print',
  excerpt: 'Discover the creative process behind our limited-edition t-shirt designs, from initial concept sketches to final printing techniques.',
  coverImage: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1471&auto=format&fit=crop',
  date: 'October 15, 2023',
  readTime: '8 min read',
  author: {
    name: 'Jason Palmer',
    avatar: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?q=80&w=1471&auto=format&fit=crop',
    bio: 'Founder & Lead Designer at TheTshirtMart. Passionate about combining art, fashion, and storytelling through unique t-shirt designs.',
  },
  category: 'Design Process',
  tags: ['Design', 'Creativity', 'Print Process', 'Artistry'],
  content: `
    <p class="lead">Creating a t-shirt design that resonates with people is both an art and a science. It combines visual aesthetics, cultural relevance, and technical expertise to produce wearable art that tells a story.</p>
    
    <h2>Finding Inspiration</h2>
    <p>Every great design begins with inspiration. At TheTshirtMart, we draw inspiration from diverse sources – street art, nature, cultural movements, abstract concepts, and even customer feedback. Our designers maintain inspiration journals, collecting images, sketches, and ideas that might evolve into future designs.</p>
    <p>The key is to remain observant and open to the world around you. A striking graffiti piece glimpsed during a morning commute, the geometric patterns in architecture, or the color palette of a sunset can all spark the beginning of a unique t-shirt design.</p>
    
    <figure>
      <img src="https://images.unsplash.com/photo-1618004652321-13a63e576b80?q=80&w=1548&auto=format&fit=crop" alt="Designer sketching concepts" class="rounded-lg" />
      <figcaption>Initial concept sketches for our Urban Gradient collection</figcaption>
    </figure>
    
    <h2>Conceptualization and Sketching</h2>
    <p>Once inspiration strikes, our designers begin sketching. These rough drawings capture the essence of the idea before it's refined. We embrace imperfection at this stage – some of our most popular designs evolved from "happy accidents" in early sketches.</p>
    <p>During conceptualization, we consider several factors:</p>
    <ul>
      <li>The story or message behind the design</li>
      <li>The target audience and how they might connect with it</li>
      <li>How the design will look when worn (not just as art on a flat surface)</li>
      <li>Technical constraints of t-shirt printing</li>
    </ul>
    
    <blockquote>
      "A great t-shirt design doesn't just look good—it makes the wearer feel something and communicates who they are without saying a word."
    </blockquote>
    
    <h2>Digital Refinement</h2>
    <p>Once we have promising sketches, we move to digital refinement using professional design software. This is where rough concepts transform into precise, print-ready artwork. Our designers manipulate every element with care:</p>
    <ul>
      <li>Color selection and contrast to ensure visual impact</li>
      <li>Typography that complements the overall aesthetic</li>
      <li>Balance and composition across the print area</li>
      <li>Scaling and positioning for different t-shirt sizes</li>
    </ul>
    <p>Each design typically goes through 5-10 iterations before we achieve the perfect balance.</p>
    
    <figure>
      <img src="https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=1528&auto=format&fit=crop" alt="T-shirt printing process" class="rounded-lg" />
      <figcaption>The printing process in action, turning digital designs into wearable art</figcaption>
    </figure>
    
    <h2>Prototyping and Testing</h2>
    <p>Digital designs can look perfect on screen but translate differently to fabric. That's why we create physical prototypes of every design. This crucial step allows us to:</p>
    <ul>
      <li>Assess how colors appear on different t-shirt materials and colors</li>
      <li>Check print quality and detail retention</li>
      <li>Evaluate the design's impact in real-world lighting conditions</li>
      <li>Test durability through wash cycles</li>
    </ul>
    <p>We often make final adjustments after seeing the prototype – perhaps brightening colors that appeared subdued on fabric or adjusting detail levels to ensure longevity.</p>
    
    <h2>Print Techniques</h2>
    <p>Different designs call for different printing techniques. At TheTshirtMart, we primarily use these methods:</p>
    <ul>
      <li><strong>Direct-to-Garment (DTG):</strong> Digital printing that excels at reproducing detailed, multi-colored designs with photographic elements.</li>
      <li><strong>Screen Printing:</strong> Traditional technique ideal for bold designs with solid colors, offering excellent durability and vibrant results.</li>
      <li><strong>Heat Transfer:</strong> Perfect for small batch production while maintaining good quality and detail.</li>
    </ul>
    <p>We select the appropriate technique based on each design's requirements, ensuring optimal results for every piece.</p>
    
    <h2>Limited Edition Philosophy</h2>
    <p>What makes our approach special is our commitment to limited editions. By capping production numbers, we ensure each design maintains its uniqueness and value. This approach also allows us to be more experimental and forward-thinking with our designs, knowing we're creating collectible pieces rather than mass-market products.</p>
    <p>When a design sells out, it's gone forever – becoming a piece of wearable art history that represents a moment in time.</p>
    
    <h2>The Designer's Pride</h2>
    <p>There's nothing quite like seeing someone walking down the street wearing your design. It's a connection between creator and wearer, a shared appreciation for creativity and self-expression. That's the true reward of t-shirt design – creating something that becomes part of someone else's identity and personal style.</p>
    
    <p>At TheTshirtMart, we're proud to transform blank t-shirts into canvases for artistic expression, cultural commentary, and personal statement. Each design represents hours of creativity, technical skill, and passionate craftsmanship – a labor of love from concept to finished product.</p>
  `,
};

// Mock related posts
const relatedPosts = [
  {
    slug: 'sustainable-fashion-practices',
    title: 'How Print-on-Demand is Revolutionizing Sustainable Fashion',
    excerpt: 'Learn how print-on-demand technology is reducing waste in the fashion industry and why it\'s the future of sustainable clothing production.',
    coverImage: 'https://images.unsplash.com/photo-1532453288672-3a27e9be9efd?q=80&w=1664&auto=format&fit=crop',
    date: 'September 28, 2023',
    author: {
      name: 'Emma Reynolds',
      avatar: 'https://images.unsplash.com/photo-1548142813-c348350df52b?q=80&w=1589&auto=format&fit=crop',
    },
    category: 'Sustainability',
  },
  {
    slug: 'interview-with-designer',
    title: 'Behind the Design: Interview with Streetwear Artist Maya Lin',
    excerpt: 'Meet Maya Lin, the talented artist behind our popular Urban Gradient collection, as she discusses her inspiration and creative process.',
    coverImage: 'https://images.unsplash.com/photo-1544441892-794166f1e3be?q=80&w=1470&auto=format&fit=crop',
    date: 'August 5, 2023',
    author: {
      name: 'Jason Palmer',
      avatar: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?q=80&w=1471&auto=format&fit=crop',
    },
    category: 'Designer Spotlight',
  },
  {
    slug: 'limited-edition-collecting',
    title: 'The Rise of Limited Edition Apparel Collecting',
    excerpt: 'Discover why limited edition t-shirts are becoming coveted collectibles and how to start your own collection of rare, exclusive designs.',
    coverImage: 'https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?q=80&w=1470&auto=format&fit=crop',
    date: 'July 22, 2023',
    author: {
      name: 'Sarah Miller',
      avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1588&auto=format&fit=crop',
    },
    category: 'Trends',
  },
];

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [visible, setVisible] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  const { toast } = useToast();
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  useEffect(() => {
    const img = new Image();
    img.src = blogPost.coverImage;
    img.onload = () => setIsImageLoaded(true);
  }, []);
  
  const handleLike = () => {
    setIsLiked(!isLiked);
    
    toast({
      title: isLiked ? "Removed from favorites" : "Added to favorites",
      description: isLiked ? "Article removed from your favorites" : "Article added to your favorites",
      variant: "default",
    });
  };
  
  const handleShare = (platform: string) => {
    toast({
      title: `Shared on ${platform}`,
      description: "Thank you for sharing this article!",
      variant: "default",
    });
    
    setShareMenuOpen(false);
  };
  
  const handleCopyLink = () => {
    toast({
      title: "Link Copied",
      description: "Article link copied to clipboard",
      variant: "default",
    });
    
    setShareMenuOpen(false);
  };
  
  return (
    <MainLayout>
      <article className="py-16">
        <Container maxWidth="xl">
          {/* Breadcrumb */}
          <div className={cn(
            "mb-8 transition-opacity duration-500",
            visible ? "opacity-100" : "opacity-0"
          )}>
            <div className="flex items-center text-sm text-gray-500">
              <Link to="/" className="hover:text-brand-orange">Home</Link>
              <span className="mx-2">/</span>
              <Link to="/blog" className="hover:text-brand-orange">Blog</Link>
              <span className="mx-2">/</span>
              <span className="text-brand-black">{blogPost.title}</span>
            </div>
          </div>
          
          {/* Article Header */}
          <header className="mb-12">
            <div className={cn(
              "transition-all duration-700",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <span className="inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-4">
                <p className="text-xs font-medium text-brand-orange">
                  {blogPost.category}
                </p>
              </span>
              
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-brand-black mb-6">
                {blogPost.title}
              </h1>
              
              <div className="flex flex-wrap items-center gap-4 text-sm text-gray-500 mb-8">
                <div className="flex items-center">
                  {blogPost.author.avatar ? (
                    <img
                      src={blogPost.author.avatar}
                      alt={blogPost.author.name}
                      className="w-8 h-8 rounded-full mr-2"
                    />
                  ) : (
                    <User size={18} className="mr-2" />
                  )}
                  <span>{blogPost.author.name}</span>
                </div>
                
                <div className="flex items-center">
                  <CalendarDays size={18} className="mr-2" />
                  <span>{blogPost.date}</span>
                </div>
                
                <span className="px-2 py-1 bg-gray-100 rounded-full">
                  {blogPost.readTime}
                </span>
              </div>
            </div>
            
            {/* Featured Image */}
            <div className={cn(
              "relative aspect-[21/9] rounded-xl overflow-hidden bg-gray-100 mb-8 transition-all duration-700 delay-200",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              {!isImageLoaded && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                  <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-orange border-t-transparent" />
                </div>
              )}
              
              <img
                src={blogPost.coverImage}
                alt={blogPost.title}
                className={cn(
                  "w-full h-full object-cover transition-opacity duration-500",
                  isImageLoaded ? "opacity-100" : "opacity-0"
                )}
              />
            </div>
          </header>
          
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
            {/* Article Content */}
            <div className="lg:col-span-8">
              <div className={cn(
                "prose prose-lg max-w-none transition-all duration-700 delay-300",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
              </div>
              
              {/* Tags */}
              <div className={cn(
                "mt-12 transition-all duration-700 delay-400",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                <div className="flex flex-wrap items-center gap-2">
                  <Tag size={18} className="text-gray-500" />
                  {blogPost.tags.map((tag) => (
                    <Link
                      key={tag}
                      to={`/blog?tag=${tag.toLowerCase()}`}
                      className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700 hover:bg-gray-200 transition-colors"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
              
              {/* Social Actions */}
              <div className={cn(
                "mt-8 flex items-center justify-between border-t border-b border-gray-200 py-6 transition-all duration-700 delay-500",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                <button
                  onClick={handleLike}
                  className={cn(
                    "flex items-center gap-2 text-sm font-medium transition-colors",
                    isLiked ? "text-red-500" : "text-gray-700 hover:text-brand-orange"
                  )}
                >
                  <Heart className={cn("h-5 w-5", isLiked && "fill-current")} />
                  <span>{isLiked ? "Liked" : "Like this article"}</span>
                </button>
                
                <div className="relative">
                  <button
                    onClick={() => setShareMenuOpen(!shareMenuOpen)}
                    className="flex items-center gap-2 text-sm font-medium text-gray-700 hover:text-brand-orange transition-colors"
                  >
                    <Share2 className="h-5 w-5" />
                    <span>Share</span>
                  </button>
                  
                  {shareMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg overflow-hidden z-20">
                      <div className="p-2">
                        <button
                          onClick={() => handleShare("Facebook")}
                          className="flex items-center gap-3 w-full px-3 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 rounded-md"
                        >
                          <Facebook size={18} className="text-blue-600" />
                          <span>Facebook</span>
                        </button>
                        
                        <button
                          onClick={() => handleShare("Twitter")}
                          className="flex items-center gap-3 w-full px-3 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 rounded-md"
                        >
                          <Twitter size={18} className="text-blue-400" />
                          <span>Twitter</span>
                        </button>
                        
                        <button
                          onClick={() => handleShare("LinkedIn")}
                          className="flex items-center gap-3 w-full px-3 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 rounded-md"
                        >
                          <Linkedin size={18} className="text-blue-700" />
                          <span>LinkedIn</span>
                        </button>
                        
                        <button
                          onClick={handleCopyLink}
                          className="flex items-center gap-3 w-full px-3 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 rounded-md"
                        >
                          <Copy size={18} className="text-gray-500" />
                          <span>Copy Link</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Author Bio */}
              <div className={cn(
                "mt-12 bg-gray-50 rounded-xl p-6 transition-all duration-700 delay-600",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                <div className="flex flex-col sm:flex-row items-center sm:items-start gap-6">
                  <img
                    src={blogPost.author.avatar}
                    alt={blogPost.author.name}
                    className="w-20 h-20 rounded-full object-cover"
                  />
                  
                  <div>
                    <h3 className="text-xl font-bold text-brand-black mb-2">
                      {blogPost.author.name}
                    </h3>
                    
                    <p className="text-gray-700 mb-4">
                      {blogPost.author.bio}
                    </p>
                    
                    <Button as={Link} to="/designer-story" variant="outline" size="sm">
                      More About the Designer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Sidebar */}
            <div className="lg:col-span-4">
              <aside className={cn(
                "transition-all duration-700 delay-400",
                visible ? "opacity-100 translate-x-0" : "opacity-0 translate-x-8"
              )}>
                {/* Author Card */}
                <div className="bg-white rounded-xl shadow-md overflow-hidden mb-8">
                  <div className="p-6">
                    <h3 className="font-bold text-brand-black mb-4">About the Author</h3>
                    
                    <div className="flex items-center gap-4 mb-4">
                      <img
                        src={blogPost.author.avatar}
                        alt={blogPost.author.name}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                      
                      <div>
                        <h4 className="font-semibold text-brand-black">
                          {blogPost.author.name}
                        </h4>
                        <p className="text-sm text-gray-500">Founder & Lead Designer</p>
                      </div>
                    </div>
                    
                    <p className="text-gray-700 text-sm mb-4">
                      {blogPost.author.bio}
                    </p>
                    
                    <Button as={Link} to="/designer-story" variant="outline" className="w-full">
                      View Profile
                    </Button>
                  </div>
                </div>
                
                {/* Newsletter */}
                <div className="bg-brand-black text-white rounded-xl overflow-hidden mb-8">
                  <div className="p-6">
                    <h3 className="font-bold text-xl mb-4">Subscribe to Our Newsletter</h3>
                    
                    <p className="text-gray-300 text-sm mb-4">
                      Get the latest articles, design updates, and exclusive offers straight to your inbox.
                    </p>
                    
                    <form className="space-y-3">
                      <input
                        type="email"
                        placeholder="Your email address"
                        className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-orange/50"
                      />
                      
                      <Button variant="default" className="w-full">
                        Subscribe
                      </Button>
                    </form>
                  </div>
                </div>
                
                {/* Related Articles */}
                <div className="bg-white rounded-xl shadow-md overflow-hidden">
                  <div className="p-6">
                    <h3 className="font-bold text-brand-black mb-4">You Might Also Like</h3>
                    
                    <div className="space-y-6">
                      {relatedPosts.map((post) => (
                        <div key={post.slug} className="flex gap-4">
                          <Link to={`/blog/${post.slug}`} className="flex-shrink-0">
                            <div className="w-20 h-20 rounded-md overflow-hidden">
                              <img
                                src={post.coverImage}
                                alt={post.title}
                                className="w-full h-full object-cover"
                              />
                            </div>
                          </Link>
                          
                          <div>
                            <Link 
                              to={`/blog/${post.slug}`}
                              className="font-medium text-brand-black hover:text-brand-orange transition-colors line-clamp-2"
                            >
                              {post.title}
                            </Link>
                            
                            <p className="text-xs text-gray-500 mt-1">{post.date}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </Container>
      </article>
    </MainLayout>
  );
};

export default BlogPost;
