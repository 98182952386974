
import { Textarea } from '@/components/ui/textarea';
import { Minus, Plus, RotateCw } from 'lucide-react';

interface TextCustomizationProps {
  customText: string;
  setCustomText: (text: string) => void;
  textColor: string;
  setTextColor: (color: string) => void;
  textSize: number;
  setTextSize: (size: number) => void;
  textFont: string;
  setTextFont: (font: string) => void;
  textRotation: number;
  setTextRotation: (rotation: number) => void;
  availableFonts: string[];
}

const TextCustomization = ({
  customText,
  setCustomText,
  textColor,
  setTextColor,
  textSize,
  setTextSize,
  textFont,
  setTextFont,
  textRotation,
  setTextRotation,
  availableFonts,
}: TextCustomizationProps) => {
  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="custom-text" className="block text-sm font-medium text-gray-700 mb-1">
          Your Text
        </label>
        <Textarea
          id="custom-text"
          value={customText}
          onChange={(e) => setCustomText(e.target.value)}
          placeholder="Enter your custom text, slogan, or message"
          className="w-full min-h-[80px] px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange outline-none transition-all"
        />
        <p className="mt-1 text-xs text-gray-500">
          Add a personal message, quote, or team name for your custom design.
        </p>
      </div>
      
      <div>
        <label htmlFor="text-color" className="block text-sm font-medium text-gray-700 mb-1">
          Text Color
        </label>
        <div className="flex items-center gap-3">
          <input
            id="text-color"
            type="color"
            value={textColor}
            onChange={(e) => setTextColor(e.target.value)}
            className="w-10 h-10 p-1 border border-gray-300 rounded"
          />
          <span className="text-sm text-gray-600">{textColor}</span>
          <div className="ml-auto flex space-x-2">
            <button 
              onClick={() => setTextColor("#000000")}
              className="h-6 w-6 bg-black rounded-full border border-gray-300"
              aria-label="Black"
            />
            <button 
              onClick={() => setTextColor("#FFFFFF")}
              className="h-6 w-6 bg-white rounded-full border border-gray-300"
              aria-label="White"
            />
            <button 
              onClick={() => setTextColor("#FF5722")}
              className="h-6 w-6 bg-brand-orange rounded-full border border-gray-300"
              aria-label="Brand Orange"
            />
          </div>
        </div>
      </div>
      
      <div>
        <label htmlFor="text-size" className="block text-sm font-medium text-gray-700 mb-1">
          Text Size
        </label>
        <div className="flex items-center gap-3">
          <button
            onClick={() => setTextSize(Math.max(12, textSize - 4))}
            className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
          >
            <Minus size={16} />
          </button>
          
          <input
            id="text-size"
            type="range"
            min="12"
            max="72"
            value={textSize}
            onChange={(e) => setTextSize(Number(e.target.value))}
            className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          
          <button
            onClick={() => setTextSize(Math.min(72, textSize + 4))}
            className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
          >
            <Plus size={16} />
          </button>
          
          <span className="text-sm text-gray-600 w-8 text-center">{textSize}</span>
        </div>
      </div>
      
      <div>
        <label htmlFor="text-font" className="block text-sm font-medium text-gray-700 mb-1">
          Font
        </label>
        <select
          id="text-font"
          value={textFont}
          onChange={(e) => setTextFont(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange outline-none transition-all"
        >
          {availableFonts.map((font: string) => (
            <option key={font} value={font} style={{ fontFamily: font }}>
              {font}
            </option>
          ))}
        </select>
        <p className="mt-1 text-xs text-gray-500">
          Choose the perfect font to match your style and message.
        </p>
      </div>
      
      <div>
        <label htmlFor="text-rotation" className="block text-sm font-medium text-gray-700 mb-1">
          Rotation
        </label>
        <div className="flex items-center gap-3">
          <button
            onClick={() => {
              const newRotation = ((textRotation - 15) % 360);
              setTextRotation(newRotation);
            }}
            className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
          >
            <RotateCw size={16} className="transform -scale-x-100" />
          </button>
          
          <input
            id="text-rotation"
            type="range"
            min="-180"
            max="180"
            value={textRotation}
            onChange={(e) => setTextRotation(Number(e.target.value))}
            className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          
          <button
            onClick={() => {
              const newRotation = ((textRotation + 15) % 360);
              setTextRotation(newRotation);
            }}
            className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
          >
            <RotateCw size={16} />
          </button>
          
          <span className="text-sm text-gray-600 w-10 text-center">{textRotation}°</span>
        </div>
      </div>
    </div>
  );
};

export default TextCustomization;
