
import React from 'react';
import { Link } from 'react-router-dom';
import { Check, ArrowLeft, Shirt } from 'lucide-react';
import { cn } from '@/lib/utils';
import Button from '@/components/ui-custom/Button';

interface ProductOptionsProps {
  product: any;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  selectedSize: string;
  setSelectedSize: (size: string) => void;
  handleAddToCart: () => void;
}

const ProductOptions = ({
  product,
  selectedColor,
  setSelectedColor,
  selectedSize,
  setSelectedSize,
  handleAddToCart,
}: ProductOptionsProps) => {
  return (
    <div className="mt-8 pt-8 border-t border-gray-200">
      <h3 className="font-bold text-brand-black mb-4">
        Product Options
      </h3>
      
      {/* T-shirt Color */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          T-Shirt Color
        </label>
        <div className="flex flex-wrap gap-2">
          {product.availableColors.map((color: string) => (
            <button
              key={color}
              onClick={() => setSelectedColor(color)}
              className={cn(
                "w-10 h-10 rounded-full border-2 flex items-center justify-center",
                selectedColor === color
                  ? "border-brand-orange"
                  : "border-transparent hover:border-gray-300"
              )}
              style={{ 
                backgroundColor: color === 'white' 
                  ? 'white' 
                  : color === 'black' 
                    ? 'black' 
                    : color === 'gray' 
                      ? '#888888' 
                      : color === 'navy' 
                        ? '#000080' 
                        : color
              }}
              aria-label={`Select ${color} color`}
            >
              {selectedColor === color && (
                <Check size={16} className={color === 'white' || color === 'gray' ? 'text-black' : 'text-white'} />
              )}
            </button>
          ))}
        </div>
        <p className="mt-1 text-xs text-gray-500">
          Choose a color that complements your design for the best results.
        </p>
      </div>
      
      {/* Size Selection */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Size
        </label>
        <div className="flex flex-wrap gap-2">
          {product.sizes.map((size: string) => (
            <button
              key={size}
              onClick={() => setSelectedSize(size)}
              className={cn(
                "h-10 min-w-10 px-3 rounded-md flex items-center justify-center border text-sm font-medium transition-all",
                selectedSize === size
                  ? "border-brand-orange bg-brand-orange/10 text-brand-orange"
                  : "border-gray-300 hover:border-gray-400 text-gray-700"
              )}
            >
              {size}
            </button>
          ))}
        </div>
        <p className="mt-1 text-xs text-gray-500">
          Our shirts are true to size. Reference our <Link to="/size-guide" className="text-brand-orange hover:underline">size guide</Link> for detailed measurements.
        </p>
      </div>
      
      {/* Price */}
      <div className="mb-6">
        <p className="text-gray-700">
          Base Price: <span className="font-semibold text-brand-black">${product.price.toFixed(2)}</span>
        </p>
        <p className="text-gray-700">
          Customization: <span className="font-semibold text-brand-black">$5.00</span>
        </p>
        <p className="text-xl font-bold text-brand-orange mt-2">
          Total: ${(product.price + 5).toFixed(2)}
        </p>
      </div>
      
      {/* Add to Cart Button */}
      <Button 
        onClick={handleAddToCart} 
        size="lg" 
        className="w-full"
      >
        Add to Cart
      </Button>
      
      {/* Shipping and Returns */}
      <div className="mt-4 pt-4 border-t border-gray-200 text-sm text-gray-600">
        <p className="flex items-center mb-2">
          <Shirt size={16} className="mr-2 text-gray-500" />
          5-7 business days production time
        </p>
        <p className="flex items-center">
          <ArrowLeft size={16} className="mr-2 text-gray-500" />
          Free returns within 30 days
        </p>
      </div>
    </div>
  );
};

export default ProductOptions;
