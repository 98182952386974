
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import Button from '../components/ui-custom/Button';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { Instagram, Twitter, Linkedin, Mail, Camera, Brush, Pencil } from 'lucide-react';

const DesignerStory = () => {
  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  return (
    <MainLayout>
      <section className="relative py-20 overflow-hidden">
        {/* Background Elements */}
        <div className="absolute inset-0 overflow-hidden -z-10">
          <div className="absolute -top-20 -right-20 w-64 h-64 rounded-full bg-brand-orange/10 blur-3xl" />
          <div className="absolute top-1/2 -left-20 w-80 h-80 rounded-full bg-brand-orange/5 blur-3xl" />
        </div>
        
        <Container>
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <span className={cn(
                "inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-4 transition-opacity duration-700",
                visible ? "opacity-100" : "opacity-0"
              )}>
                <p className="text-xs font-medium text-brand-orange">
                  Meet the Designer
                </p>
              </span>
              
              <h1 className={cn(
                "text-4xl md:text-5xl font-bold mb-6 transition-all duration-700",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                The Creative Journey
              </h1>
              
              <p className={cn(
                "text-brand-dark-gray text-lg transition-all duration-700 delay-100",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                Discover the story, inspiration, and creative process behind TheTshirtMart's unique designs.
              </p>
            </div>
            
            {/* Designer Profile */}
            <div className={cn(
              "flex flex-col md:flex-row items-center gap-12 mb-16 transition-all duration-700 delay-200",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <div className="relative w-64 h-64 rounded-full overflow-hidden flex-shrink-0">
                <img
                  src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?q=80&w=1471&auto=format&fit=crop"
                  alt="Designer Portrait"
                  className="w-full h-full object-cover"
                />
                
                <div className="absolute inset-0 rounded-full ring-2 ring-brand-orange/20"></div>
              </div>
              
              <div>
                <h2 className="text-3xl font-bold text-brand-black mb-3">
                  Jason Palmer
                </h2>
                
                <p className="text-brand-orange font-medium mb-4">
                  Founder & Lead Designer
                </p>
                
                <p className="text-gray-700 mb-6">
                  A passionate artist and designer with over 10 years of experience in apparel design, Jason founded TheTshirtMart with a vision to create unique, limited-edition designs that push the boundaries of creativity and self-expression. His background in graphic design and street art influences his distinctive style that blends urban aesthetics with contemporary design trends.
                </p>
                
                <div className="flex items-center gap-4">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center text-brand-orange hover:bg-brand-orange hover:text-white transition-all"
                    aria-label="Instagram"
                  >
                    <Instagram size={18} />
                  </a>
                  
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center text-brand-orange hover:bg-brand-orange hover:text-white transition-all"
                    aria-label="Twitter"
                  >
                    <Twitter size={18} />
                  </a>
                  
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center text-brand-orange hover:bg-brand-orange hover:text-white transition-all"
                    aria-label="LinkedIn"
                  >
                    <Linkedin size={18} />
                  </a>
                  
                  <a
                    href="mailto:jason@thetshirtmart.com"
                    className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center text-brand-orange hover:bg-brand-orange hover:text-white transition-all"
                    aria-label="Email"
                  >
                    <Mail size={18} />
                  </a>
                </div>
              </div>
            </div>
            
            {/* Design Philosophy */}
            <div className={cn(
              "mb-16 transition-all duration-700 delay-300",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h2 className="text-2xl font-bold text-brand-black mb-6">
                Design Philosophy
              </h2>
              
              <div className="bg-white rounded-xl shadow-md p-8 mb-8">
                <p className="text-lg text-gray-700 italic border-l-4 border-brand-orange pl-4 mb-6">
                  "I believe that a great t-shirt design should tell a story, spark conversation, and most importantly, make the wearer feel uniquely themselves. My designs are influenced by everything from urban street art to minimalist aesthetics, always with a focus on creating something that stands the test of time."
                </p>
                
                <p className="text-gray-700 mb-6">
                  At TheTshirtMart, we don't follow trends—we create them. Each design begins with a concept, a feeling, or a message that we want to convey. We then refine this idea through multiple iterations, experimenting with colors, shapes, and compositions until we've created something truly special.
                </p>
                
                <p className="text-gray-700">
                  What makes our approach unique is our commitment to limited editions. By producing our designs in small batches, we ensure that our customers receive something exclusive and special. Once a design sells out, it's gone forever—making each piece a collector's item.
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <div className="w-14 h-14 rounded-full bg-brand-orange/10 flex items-center justify-center mb-4">
                    <Brush className="h-6 w-6 text-brand-orange" />
                  </div>
                  <h3 className="font-semibold text-brand-black mb-2">Artistic Integrity</h3>
                  <p className="text-gray-700 text-sm">
                    We never compromise on design quality or artistic vision, even if it means taking more time to perfect a concept.
                  </p>
                </div>
                
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <div className="w-14 h-14 rounded-full bg-brand-orange/10 flex items-center justify-center mb-4">
                    <Pencil className="h-6 w-6 text-brand-orange" />
                  </div>
                  <h3 className="font-semibold text-brand-black mb-2">Intentional Design</h3>
                  <p className="text-gray-700 text-sm">
                    Every element of our designs serves a purpose—no arbitrary decisions or fillers. Everything has meaning.
                  </p>
                </div>
                
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <div className="w-14 h-14 rounded-full bg-brand-orange/10 flex items-center justify-center mb-4">
                    <Camera className="h-6 w-6 text-brand-orange" />
                  </div>
                  <h3 className="font-semibold text-brand-black mb-2">Visual Storytelling</h3>
                  <p className="text-gray-700 text-sm">
                    We believe in creating designs that tell a story, provoke thought, or evoke emotion through visual elements.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Creative Process */}
            <div className={cn(
              "mb-16 transition-all duration-700 delay-400",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h2 className="text-2xl font-bold text-brand-black mb-6">
                The Creative Process
              </h2>
              
              <div className="relative">
                {/* Timeline */}
                <div className="absolute top-0 bottom-0 left-8 md:left-1/2 w-px bg-gray-200 transform -translate-x-1/2"></div>
                
                {/* Steps */}
                <div className="space-y-12">
                  {/* Step 1 */}
                  <div className="relative">
                    <div className="flex flex-col md:flex-row md:items-center">
                      <div className="flex md:flex-col items-start md:items-end md:w-1/2 pr-8 md:text-right">
                        <div className="absolute left-0 md:left-1/2 w-16 h-16 bg-brand-orange/10 rounded-full transform -translate-x-1/2 flex items-center justify-center z-10">
                          <span className="text-xl font-bold text-brand-orange">1</span>
                        </div>
                        <div className="pl-20 md:pl-0">
                          <h3 className="text-xl font-bold text-brand-black mb-2">Inspiration & Concept</h3>
                          <p className="text-gray-700">Every design begins with inspiration—from street art, current events, nature, or abstract concepts. We sketch initial ideas by hand to capture the raw creative energy.</p>
                        </div>
                      </div>
                      
                      <div className="md:w-1/2 pl-20 md:pl-8 mt-4 md:mt-0"></div>
                    </div>
                  </div>
                  
                  {/* Step 2 */}
                  <div className="relative">
                    <div className="flex flex-col md:flex-row md:items-center">
                      <div className="md:w-1/2 pr-8 text-right hidden md:block"></div>
                      
                      <div className="flex md:flex-col items-start md:w-1/2 pl-20 md:pl-8">
                        <div className="absolute left-0 md:left-1/2 w-16 h-16 bg-brand-orange/10 rounded-full transform -translate-x-1/2 flex items-center justify-center z-10">
                          <span className="text-xl font-bold text-brand-orange">2</span>
                        </div>
                        <div>
                          <h3 className="text-xl font-bold text-brand-black mb-2">Digital Refinement</h3>
                          <p className="text-gray-700">Promising concepts are digitized and refined through multiple iterations. We experiment with composition, color palettes, and typography to create a balanced and impactful design.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Step 3 */}
                  <div className="relative">
                    <div className="flex flex-col md:flex-row md:items-center">
                      <div className="flex md:flex-col items-start md:items-end md:w-1/2 pr-8 md:text-right">
                        <div className="absolute left-0 md:left-1/2 w-16 h-16 bg-brand-orange/10 rounded-full transform -translate-x-1/2 flex items-center justify-center z-10">
                          <span className="text-xl font-bold text-brand-orange">3</span>
                        </div>
                        <div className="pl-20 md:pl-0">
                          <h3 className="text-xl font-bold text-brand-black mb-2">Prototype & Testing</h3>
                          <p className="text-gray-700">We create physical samples to test how the design looks in real life, making adjustments for how colors appear on fabric, checking print quality, and evaluating the overall impact.</p>
                        </div>
                      </div>
                      
                      <div className="md:w-1/2 pl-20 md:pl-8 mt-4 md:mt-0"></div>
                    </div>
                  </div>
                  
                  {/* Step 4 */}
                  <div className="relative">
                    <div className="flex flex-col md:flex-row md:items-center">
                      <div className="md:w-1/2 pr-8 text-right hidden md:block"></div>
                      
                      <div className="flex md:flex-col items-start md:w-1/2 pl-20 md:pl-8">
                        <div className="absolute left-0 md:left-1/2 w-16 h-16 bg-brand-orange/10 rounded-full transform -translate-x-1/2 flex items-center justify-center z-10">
                          <span className="text-xl font-bold text-brand-orange">4</span>
                        </div>
                        <div>
                          <h3 className="text-xl font-bold text-brand-black mb-2">Limited Edition Release</h3>
                          <p className="text-gray-700">Final designs are released in limited quantities, ensuring exclusivity and collectible status. Each batch is numbered and once sold out, the design is officially retired.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Gallery */}
            <div className={cn(
              "mb-16 transition-all duration-700 delay-500",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h2 className="text-2xl font-bold text-brand-black mb-6">
                Behind the Scenes
              </h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="rounded-xl overflow-hidden aspect-square">
                  <img
                    src="https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1471&auto=format&fit=crop"
                    alt="Designer sketching concepts"
                    className="w-full h-full object-cover"
                  />
                </div>
                
                <div className="rounded-xl overflow-hidden aspect-square">
                  <img
                    src="https://images.unsplash.com/photo-1618004652321-13a63e576b80?q=80&w=1548&auto=format&fit=crop"
                    alt="Digital design work"
                    className="w-full h-full object-cover"
                  />
                </div>
                
                <div className="rounded-xl overflow-hidden aspect-square">
                  <img
                    src="https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=1528&auto=format&fit=crop"
                    alt="T-shirt printing process"
                    className="w-full h-full object-cover"
                  />
                </div>
                
                <div className="rounded-xl overflow-hidden aspect-square md:col-span-2">
                  <img
                    src="https://images.unsplash.com/photo-1574144113084-b6f450cc5e0c?q=80&w=1528&auto=format&fit=crop"
                    alt="Designer workshop"
                    className="w-full h-full object-cover"
                  />
                </div>
                
                <div className="rounded-xl overflow-hidden aspect-square">
                  <img
                    src="https://images.unsplash.com/photo-1608447714925-598effadbf9d?q=80&w=1506&auto=format&fit=crop"
                    alt="Quality control check"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
            
            {/* Call to Action */}
            <div className={cn(
              "text-center transition-all duration-700 delay-600",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h2 className="text-2xl font-bold text-brand-black mb-4">
                Ready to Own a Piece of Wearable Art?
              </h2>
              
              <p className="text-gray-700 mb-8">
                Check out our latest collection of limited-edition designs before they're gone forever.
              </p>
              
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Button as={Link} to="/shop" size="lg">
                  Shop Collection
                </Button>
                
                <Button as={Link} to="/limited-drops" variant="outline" size="lg">
                  View Limited Drops
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default DesignerStory;
