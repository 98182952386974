
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Check, Shirt } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

interface ProductPreviewProps {
  product: any;
  previewLoaded: boolean;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  visible: boolean;
}

const ProductPreview = ({ product, previewLoaded, canvasRef, visible }: ProductPreviewProps) => {
  return (
    <div className={cn(
      "transition-all duration-700",
      visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
    )}>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-brand-black mb-6">
            <Link to={`/products/${product?.id}`} className="flex items-center hover:text-brand-orange transition-colors">
              <ArrowLeft size={20} className="mr-2" />
              Customizing: {product?.title}
            </Link>
          </h2>
          
          <div className="relative bg-gray-50 rounded-lg overflow-hidden aspect-square mb-4">
            {!previewLoaded && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-orange border-t-transparent" />
              </div>
            )}
            
            <canvas
              ref={canvasRef}
              width={500}
              height={500}
              className="w-full h-full object-contain"
            />
          </div>
          
          <div className="text-center text-sm text-gray-500">
            <p>This is a preview. Actual product may vary slightly.</p>
          </div>
        </div>
        
        {/* Product Features Card */}
        <div className="px-6 pb-6">
          <Card>
            <CardHeader className="pb-3">
              <CardTitle className="text-xl flex items-center">
                <Shirt className="mr-2 text-brand-orange" />
                Product Features
              </CardTitle>
              <CardDescription>
                Premium quality for your custom design
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2">
                {product.features.map((feature: string, index: number) => (
                  <li key={index} className="flex items-start">
                    <Check size={18} className="mr-2 text-green-500 mt-0.5 flex-shrink-0" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              
              <Separator className="my-4" />
              
              <div className="text-sm text-gray-700">
                <p className="mb-2">{product.description}</p>
                <p className="font-medium text-brand-orange">
                  Free shipping on orders over $50!
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProductPreview;
