
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'

// Error handling for root rendering
try {
  const rootElement = document.getElementById("root");
  
  if (!rootElement) {
    throw new Error("Root element not found. Make sure there is a div with id 'root' in your HTML.");
  }
  
  createRoot(rootElement).render(<App />);
  
  console.log("Application successfully mounted to DOM");
} catch (error) {
  console.error("Failed to render application:", error);
  
  // Display a visible error message on the page
  document.body.innerHTML = `
    <div style="font-family: sans-serif; padding: 20px; text-align: center;">
      <h1>Something went wrong</h1>
      <p>The application failed to load. Please try refreshing the page.</p>
      <p style="color: #666; font-size: 14px;">Technical details: ${error instanceof Error ? error.message : 'Unknown error'}</p>
    </div>
  `;
}
