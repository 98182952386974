
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Container from "../components/ui-custom/Container";
import Button from "../components/ui-custom/Button";
import MainLayout from "../components/layout/MainLayout";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Log the 404 error
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );

    // Automatically redirect to home page after 5 seconds for all 404 errors
    const redirectTimer = setTimeout(() => {
      navigate("/", { replace: true });
    }, 5000);

    return () => clearTimeout(redirectTimer);
  }, [location.pathname, navigate]);

  return (
    <MainLayout>
      <Container maxWidth="lg">
        <div className="min-h-[70vh] flex flex-col items-center justify-center text-center py-16">
          <h1 className="text-7xl sm:text-9xl font-bold text-brand-orange mb-4">404</h1>
          <h2 className="text-2xl sm:text-3xl font-medium mb-6">Page Not Found</h2>
          <p className="text-lg text-brand-gray max-w-md mb-8">
            We couldn't find the page you're looking for. It might have been moved or doesn't exist.
            You will be redirected to the homepage automatically in 5 seconds.
          </p>
          <Button as={Link} to="/" size="lg">
            Return to Home
          </Button>
        </div>
      </Container>
    </MainLayout>
  );
};

export default NotFound;
