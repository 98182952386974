
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import Button from '../components/ui-custom/Button';
import { Link } from 'react-router-dom';

// FAQ categories and questions
const faqCategories = [
  {
    id: 'ordering',
    name: 'Ordering & Payment',
    questions: [
      {
        id: 'payment-methods',
        question: 'What payment methods do you accept?',
        answer: 'We accept all major credit/debit cards (Visa, Mastercard, American Express), PayPal, Google Pay, and Apple Pay. All transactions are processed securely to protect your information.'
      },
      {
        id: 'order-processing',
        question: 'How long does order processing take?',
        answer: 'Since we use print-on-demand technology, your order typically takes 2-3 business days to process before shipping. Limited edition items may take an additional 1-2 days as we ensure quality control for these special designs.'
      },
      {
        id: 'order-status',
        question: 'How can I check my order status?',
        answer: 'You can track your order status by logging into your account and viewing your order history. You\'ll also receive email updates when your order is processed, shipped, and delivered.'
      },
      {
        id: 'order-cancel',
        question: 'Can I cancel or change my order?',
        answer: 'You can request order cancellation or changes within 2 hours of placing your order by contacting our customer service team. After this window, orders enter production and cannot be modified or cancelled.'
      },
    ]
  },
  {
    id: 'shipping',
    name: 'Shipping & Delivery',
    questions: [
      {
        id: 'shipping-time',
        question: 'How long will shipping take?',
        answer: 'Domestic orders typically arrive within 3-7 business days after processing. International shipping varies by location but generally takes 7-14 business days. Expedited shipping options are available at checkout for faster delivery.'
      },
      {
        id: 'shipping-cost',
        question: 'What are your shipping rates?',
        answer: 'Domestic shipping starts at $4.99, with free shipping on orders over $50. International shipping rates vary by country and are calculated at checkout. Any applicable customs fees or import duties are the responsibility of the customer.'
      },
      {
        id: 'shipping-international',
        question: 'Do you ship internationally?',
        answer: 'Yes, we ship to most countries worldwide. International shipping rates and delivery times vary by location. Please note that any customs fees, import duties, or taxes are the responsibility of the recipient.'
      },
      {
        id: 'lost-packages',
        question: 'What happens if my package is lost or damaged?',
        answer: 'If your package is lost or arrives damaged, please contact our customer service team within 7 days of the expected delivery date. We\'ll work with the shipping carrier to locate your package or process a replacement order.'
      },
    ]
  },
  {
    id: 'products',
    name: 'Products & Customization',
    questions: [
      {
        id: 'product-quality',
        question: 'What is the quality of your t-shirts?',
        answer: 'Our t-shirts are made from premium 100% combed cotton (180 gsm) for superior softness and durability. We use high-quality water-based inks for vibrant, long-lasting prints that won\'t crack or fade with proper care.'
      },
      {
        id: 'product-sizing',
        question: 'How do I find the right size?',
        answer: 'Each product page includes a detailed size guide with measurements. Our t-shirts generally run true to size with a standard unisex fit. If you\'re between sizes, we recommend sizing up for a more relaxed fit or sizing down for a more fitted look.'
      },
      {
        id: 'limited-editions',
        question: 'What does "limited edition" mean?',
        answer: 'Limited edition designs are produced in small batches, usually between 25-100 pieces. Once they sell out, they will not be reprinted. This ensures exclusivity and makes each piece a collector\'s item. Each limited drop has a counter showing how many remain available.'
      },
      {
        id: 'customization',
        question: 'How does the customization tool work?',
        answer: 'Our customization tool allows you to add personal text, choose colors, or upload images to customize select designs. Simply select "Customize" on eligible products, use the interactive tool to make your changes, and preview the final design before adding to cart.'
      },
    ]
  },
  {
    id: 'returns',
    name: 'Returns & Exchanges',
    questions: [
      {
        id: 'return-policy',
        question: 'What is your return policy?',
        answer: 'We offer a 30-day return policy for unworn, unwashed items in original condition with tags attached. Custom or personalized items cannot be returned unless there is a print defect or other quality issue.'
      },
      {
        id: 'exchange-process',
        question: 'How do I exchange for a different size?',
        answer: 'To exchange for a different size, please initiate a return request through your account and specify that you\'d like an exchange. Once your return is processed, we\'ll send the new size. Note that exchanges are subject to availability.'
      },
      {
        id: 'defective-items',
        question: 'What if I receive a defective item?',
        answer: 'If you receive a defective item, please contact us within 7 days with photos of the defect. We\'ll arrange a replacement or refund at no additional cost to you, including return shipping for defective merchandise.'
      },
      {
        id: 'return-shipping',
        question: 'Who pays for return shipping?',
        answer: 'Customers are responsible for return shipping costs except in cases of defective merchandise or incorrect items sent. We recommend using a trackable shipping method for returns to ensure they reach our facility.'
      },
    ]
  },
  {
    id: 'account',
    name: 'Account & Loyalty',
    questions: [
      {
        id: 'create-account',
        question: 'Do I need an account to place an order?',
        answer: 'No, you can check out as a guest. However, creating an account offers benefits like order tracking, faster checkout, access to exclusive offers, and the ability to earn and redeem loyalty points.'
      },
      {
        id: 'loyalty-program',
        question: 'How does your loyalty program work?',
        answer: 'Our loyalty program rewards you with points for purchases, reviews, social shares, and referrals. You earn 1 point per $1 spent, and points can be redeemed for discounts, exclusive merchandise, or early access to limited drops.'
      },
      {
        id: 'password-reset',
        question: 'How do I reset my password?',
        answer: 'To reset your password, click "Forgot Password" on the login page, enter your email address, and follow the reset instructions sent to your inbox. If you don\'t receive the email, check your spam folder or contact customer support.'
      },
      {
        id: 'account-deletion',
        question: 'Can I delete my account?',
        answer: 'Yes, you can request account deletion from your account settings page or by contacting customer support. Please note that this will permanently remove your order history and any accumulated loyalty points.'
      },
    ]
  },
];

const FAQ = () => {
  const [visible, setVisible] = useState(false);
  const [openQuestions, setOpenQuestions] = useState<Record<string, boolean>>({});
  const [activeCategory, setActiveCategory] = useState('ordering');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  const toggleQuestion = (questionId: string) => {
    setOpenQuestions(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };
  
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }
    
    const query = searchQuery.toLowerCase();
    const results: Array<any> = [];
    
    faqCategories.forEach(category => {
      category.questions.forEach(question => {
        if (
          question.question.toLowerCase().includes(query) ||
          question.answer.toLowerCase().includes(query)
        ) {
          results.push({
            ...question,
            category: category.name
          });
        }
      });
    });
    
    setSearchResults(results);
  };
  
  const currentCategory = faqCategories.find(cat => cat.id === activeCategory) || faqCategories[0];
  
  return (
    <MainLayout>
      <section className="py-20 bg-brand-light-gray">
        <Container>
          <div className="text-center mb-16">
            <span className={cn(
              "inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-4 transition-opacity duration-700",
              visible ? "opacity-100" : "opacity-0"
            )}>
              <p className="text-xs font-medium text-brand-orange">
                Help Center
              </p>
            </span>
            
            <h1 className={cn(
              "text-4xl md:text-5xl font-bold mb-6 transition-all duration-700",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              Frequently Asked Questions
            </h1>
            
            <p className={cn(
              "max-w-2xl mx-auto text-brand-dark-gray text-lg transition-all duration-700 delay-100",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              Find answers to common questions about our products, ordering process, shipping, and more.
            </p>
          </div>
          
          {/* Search */}
          <div className={cn(
            "max-w-2xl mx-auto mb-12 transition-all duration-700 delay-200",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <form onSubmit={handleSearch} className="relative">
              <input
                type="text"
                placeholder="Search for answers..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-3 pl-12 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange transition-all"
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              <Button type="submit" className="absolute right-2 top-1/2 transform -translate-y-1/2" size="sm">
                Search
              </Button>
            </form>
          </div>
          
          {/* Search Results */}
          {searchResults.length > 0 && (
            <div className={cn(
              "max-w-3xl mx-auto mb-12 transition-all duration-700",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h2 className="text-xl font-bold text-brand-black mb-4">
                Search Results ({searchResults.length})
              </h2>
              
              <div className="space-y-4">
                {searchResults.map((result) => (
                  <div key={result.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <button
                      onClick={() => toggleQuestion(result.id)}
                      className="w-full text-left px-6 py-4 flex items-center justify-between"
                    >
                      <div>
                        <span className="text-sm text-brand-orange">{result.category}</span>
                        <h3 className="font-medium text-brand-black">{result.question}</h3>
                      </div>
                      {openQuestions[result.id] ? (
                        <ChevronUp className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronDown className="h-5 w-5 text-gray-500" />
                      )}
                    </button>
                    
                    {openQuestions[result.id] && (
                      <div className="px-6 py-4 border-t border-gray-100 bg-gray-50">
                        <p className="text-gray-700">{result.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              
              <div className="mt-6 text-center">
                <button
                  onClick={() => setSearchResults([])}
                  className="text-brand-orange hover:underline"
                >
                  Clear Search Results
                </button>
              </div>
            </div>
          )}
          
          {/* FAQ Categories and Questions */}
          {searchResults.length === 0 && (
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
              {/* Categories */}
              <div className={cn(
                "transition-all duration-700 delay-300",
                visible ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-8"
              )}>
                <div className="bg-white rounded-lg shadow-md overflow-hidden sticky top-24">
                  <div className="p-4 bg-brand-orange/10">
                    <h2 className="font-bold text-brand-black">Categories</h2>
                  </div>
                  
                  <div className="p-2">
                    {faqCategories.map((category) => (
                      <button
                        key={category.id}
                        onClick={() => setActiveCategory(category.id)}
                        className={cn(
                          "w-full text-left px-4 py-3 rounded-md transition-colors",
                          activeCategory === category.id
                            ? "bg-brand-orange/10 text-brand-orange font-medium"
                            : "hover:bg-gray-100 text-gray-700"
                        )}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                  
                  <div className="p-4 border-t border-gray-200">
                    <p className="text-sm text-gray-600 mb-4">
                      Can't find what you're looking for?
                    </p>
                    <Button as={Link} to="/contact" variant="outline" className="w-full">
                      Contact Support
                    </Button>
                  </div>
                </div>
              </div>
              
              {/* Questions */}
              <div className={cn(
                "lg:col-span-3 transition-all duration-700 delay-400",
                visible ? "opacity-100 translate-x-0" : "opacity-0 translate-x-8"
              )}>
                <h2 className="text-2xl font-bold text-brand-black mb-6">
                  {currentCategory.name}
                </h2>
                
                <div className="space-y-4">
                  {currentCategory.questions.map((question) => (
                    <div key={question.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                      <button
                        onClick={() => toggleQuestion(question.id)}
                        className="w-full text-left px-6 py-4 flex items-center justify-between"
                      >
                        <h3 className="font-medium text-brand-black">{question.question}</h3>
                        {openQuestions[question.id] ? (
                          <ChevronUp className="h-5 w-5 text-gray-500 flex-shrink-0" />
                        ) : (
                          <ChevronDown className="h-5 w-5 text-gray-500 flex-shrink-0" />
                        )}
                      </button>
                      
                      {openQuestions[question.id] && (
                        <div className="px-6 py-4 border-t border-gray-100 bg-gray-50">
                          <p className="text-gray-700">{question.answer}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          
          {/* Contact Section */}
          <div className={cn(
            "mt-20 bg-white rounded-xl shadow-lg p-8 transition-all duration-700 delay-500",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <div className="text-center max-w-2xl mx-auto">
              <h2 className="text-2xl font-bold text-brand-black mb-4">
                Still Have Questions?
              </h2>
              
              <p className="text-gray-700 mb-6">
                Our customer support team is here to help. Get in touch and we'll get back to you as soon as possible.
              </p>
              
              <Button as={Link} to="/contact" size="lg">
                Contact Support
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default FAQ;
