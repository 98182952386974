
import { Link } from 'react-router-dom';
import Container from '../ui-custom/Container';
import Logo from '../ui-custom/Logo';
import { Instagram, Facebook, Twitter } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-brand-black text-white py-16">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Brand Section */}
          <div className="md:col-span-1">
            <Logo variant="white" />
            <p className="mt-4 text-gray-400 text-sm">
              Premium limited-edition t-shirts with exclusive designs that stand out from the crowd.
            </p>
            <div className="flex space-x-4 mt-6">
              <a 
                href="https://instagram.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center rounded-full bg-white/10 hover:bg-brand-orange transition-colors"
                aria-label="Instagram"
              >
                <Instagram size={18} />
              </a>
              <a 
                href="https://facebook.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center rounded-full bg-white/10 hover:bg-brand-orange transition-colors"
                aria-label="Facebook"
              >
                <Facebook size={18} />
              </a>
              <a 
                href="https://twitter.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center rounded-full bg-white/10 hover:bg-brand-orange transition-colors"
                aria-label="Twitter"
              >
                <Twitter size={18} />
              </a>
            </div>
          </div>
          
          {/* Shop Links */}
          <div className="md:col-span-1">
            <h3 className="text-white font-medium mb-4">Shop</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/shop" className="text-gray-400 hover:text-brand-orange transition-colors">All Products</Link>
              </li>
              <li>
                <Link to="/limited-drops" className="text-gray-400 hover:text-brand-orange transition-colors">Limited Drops</Link>
              </li>
              <li>
                <Link to="/customize" className="text-gray-400 hover:text-brand-orange transition-colors">Customize</Link>
              </li>
              <li>
                <Link to="/collaborations" className="text-gray-400 hover:text-brand-orange transition-colors">Collaborations</Link>
              </li>
            </ul>
          </div>
          
          {/* Company Links */}
          <div className="md:col-span-1">
            <h3 className="text-white font-medium mb-4">Company</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-gray-400 hover:text-brand-orange transition-colors">About Us</Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-brand-orange transition-colors">Contact</Link>
              </li>
              <li>
                <Link to="/careers" className="text-gray-400 hover:text-brand-orange transition-colors">Careers</Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-400 hover:text-brand-orange transition-colors">Blog</Link>
              </li>
            </ul>
          </div>
          
          {/* Support Links */}
          <div className="md:col-span-1">
            <h3 className="text-white font-medium mb-4">Support</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/faq" className="text-gray-400 hover:text-brand-orange transition-colors">FAQs</Link>
              </li>
              <li>
                <Link to="/shipping" className="text-gray-400 hover:text-brand-orange transition-colors">Shipping</Link>
              </li>
              <li>
                <Link to="/returns" className="text-gray-400 hover:text-brand-orange transition-colors">Returns</Link>
              </li>
              <li>
                <Link to="/size-guide" className="text-gray-400 hover:text-brand-orange transition-colors">Size Guide</Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-white/10 mt-12 pt-8 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-sm text-gray-400">
            &copy; {currentYear} TheTshirtMart. All rights reserved.
          </p>
          <div className="flex space-x-6 mt-4 sm:mt-0">
            <Link to="/privacy" className="text-xs text-gray-400 hover:text-brand-orange transition-colors">
              Privacy Policy
            </Link>
            <Link to="/terms" className="text-xs text-gray-400 hover:text-brand-orange transition-colors">
              Terms of Service
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
