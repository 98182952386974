import { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import { cn } from '@/lib/utils';
import { useToast } from '@/hooks/use-toast';
import ProductPreview from '@/components/customize/ProductPreview';
import CustomizationControls from '@/components/customize/CustomizationControls';
import ProductOptions from '@/components/customize/ProductOptions';
import MarketingSection from '@/components/customize/MarketingSection';
import { updateCanvas } from '@/utils/canvasHelpers';

// Mock product data
const products = [
  {
    id: '1',
    title: 'Urban Gradient Tee',
    price: 34.99,
    imageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    baseColor: 'white',
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
    canCustomize: true,
    customizableAreas: ['front'],
    availableColors: ['white', 'black', 'gray', 'navy'],
    availableFonts: ['Arial', 'Helvetica', 'Times New Roman', 'Comic Sans MS', 'Impact'],
    description: 'Our premium Urban Gradient Tee is made from 100% ringspun cotton for ultimate comfort and durability. Perfect for expressing your unique style with custom designs.',
    features: [
      'Premium ringspun cotton',
      'Pre-shrunk fabric',
      'Tear-away label',
      'High-quality print that won\'t fade',
      'Ethically sourced materials',
    ]
  },
];

const CustomizeProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [customText, setCustomText] = useState('');
  const [textColor, setTextColor] = useState('#000000');
  const [textSize, setTextSize] = useState(36);
  const [textFont, setTextFont] = useState('Arial');
  const [textRotation, setTextRotation] = useState(0);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('text'); // 'text' or 'image'
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageScale, setImageScale] = useState(100);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  
  useEffect(() => {
    // Simulate API call to fetch product details
    const fetchProduct = () => {
      setLoading(true);
      
      // Handle "new" as a special case - use the first product
      if (productId === 'new') {
        setTimeout(() => {
          setProduct(products[0]);
          setLoading(false);
          setSelectedSize(products[0].sizes[0]);
          setSelectedColor(products[0].availableColors[0]);
        }, 500);
        return;
      }
      
      // Find product by ID from mock data
      const foundProduct = products.find(p => p.id === productId);
      
      setTimeout(() => {
        setProduct(foundProduct || null);
        setLoading(false);
        if (foundProduct) {
          setSelectedSize(foundProduct.sizes[0]);
          setSelectedColor(foundProduct.availableColors[0]);
        }
      }, 500);
    };
    
    fetchProduct();

    // Update page title and meta description for SEO
    document.title = productId ? `Customize Your ${products[0]?.title || 'T-Shirt'} | Printastic Vibe` : 'Custom T-Shirt Design | Printastic Vibe';
    
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Create your own custom designed t-shirt. Add text, upload images, and choose colors to express your unique style with Printastic Vibe\'s premium customization tools.');
    }
  }, [productId]);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  useEffect(() => {
    if (product && !loading && canvasRef.current) {
      updateCanvas({
        canvas: canvasRef.current,
        customText,
        textColor,
        textSize,
        textFont,
        textRotation,
        uploadedImage,
        imageScale,
        activeTab
      });
      setPreviewLoaded(true);
    }
  }, [product, loading, customText, textColor, textSize, textFont, textRotation, selectedColor, uploadedImage, imageScale, activeTab]);
  
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      toast({
        title: "File too large",
        description: "Please select an image under 5MB",
        variant: "destructive",
      });
      return;
    }
    
    const reader = new FileReader();
    reader.onload = (event) => {
      setUploadedImage(event.target?.result as string);
      setActiveTab('image');
    };
    reader.readAsDataURL(file);
  };
  
  const handleAddToCart = () => {
    if (!selectedSize) {
      toast({
        title: "Please select a size",
        description: "You need to select a size before adding to cart",
        variant: "destructive",
      });
      return;
    }
    
    toast({
      title: "Customized product added to cart!",
      description: `${product.title} (${selectedSize}) has been added to your cart`,
      variant: "default",
    });
  };
  
  const clearCustomization = () => {
    if (activeTab === 'text') {
      setCustomText('');
    } else {
      setUploadedImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
    setTextRotation(0);
    setImageScale(100);
  };

  const handleSaveDesign = () => {
    // Save customization logic would go here
    toast({
      title: "Design saved!",
      description: "Your customization has been saved.",
      variant: "default",
    });
  };
  
  if (loading) {
    return (
      <MainLayout>
        <Container>
          <div className="py-20 flex items-center justify-center">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-orange border-t-transparent" />
          </div>
        </Container>
      </MainLayout>
    );
  }
  
  if (!product) {
    return (
      <MainLayout>
        <Container>
          <div className="py-20 text-center">
            <h1 className="text-3xl font-bold mb-4">Product Not Found</h1>
            <p className="text-gray-600 mb-8">The product you're looking for doesn't exist or cannot be customized.</p>
            <Link to="/shop" className="inline-flex items-center justify-center rounded-md bg-brand-black px-6 py-3 text-white font-medium hover:bg-gray-800 transition-colors">
              Return to Shop
            </Link>
          </div>
        </Container>
      </MainLayout>
    );
  }
  
  return (
    <MainLayout>
      <section className="py-16">
        <Container>
          {/* Breadcrumb */}
          <div className={cn(
            "mb-8 transition-opacity duration-500",
            visible ? "opacity-100" : "opacity-0"
          )}>
            <div className="flex items-center text-sm text-gray-500">
              <Link to="/" className="hover:text-brand-orange">Home</Link>
              <span className="mx-2">/</span>
              <Link to="/shop" className="hover:text-brand-orange">Shop</Link>
              <span className="mx-2">/</span>
              {productId !== 'new' && (
                <>
                  <Link to={`/products/${product?.id}`} className="hover:text-brand-orange">{product?.title}</Link>
                  <span className="mx-2">/</span>
                </>
              )}
              <span className="text-brand-black">Customize</span>
            </div>
          </div>
          
          {/* SEO Heading */}
          <div className={cn(
            "mb-8 transition-opacity duration-500",
            visible ? "opacity-100" : "opacity-0"
          )}>
            <h1 className="text-4xl font-bold text-brand-black mb-3">Design Your Custom {product?.title}</h1>
            <p className="text-lg text-gray-600 max-w-3xl">
              Express yourself with our easy-to-use customization tools. Add text, upload images, and create a one-of-a-kind design that's uniquely yours.
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            {/* Preview Section */}
            <ProductPreview 
              product={product}
              previewLoaded={previewLoaded}
              canvasRef={canvasRef}
              visible={visible}
            />
            
            {/* Customization Controls */}
            <div className="space-y-6">
              <CustomizationControls 
                product={product}
                visible={visible}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                customText={customText}
                setCustomText={setCustomText}
                textColor={textColor}
                setTextColor={setTextColor}
                textSize={textSize}
                setTextSize={setTextSize}
                textFont={textFont}
                setTextFont={setTextFont}
                textRotation={textRotation}
                setTextRotation={setTextRotation}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                imageScale={imageScale}
                setImageScale={setImageScale}
                fileInputRef={fileInputRef}
                handleImageUpload={handleImageUpload}
                clearCustomization={clearCustomization}
                onSaveDesign={handleSaveDesign}
              />
              
              {/* Product Options Section */}
              <div className={cn(
                "bg-white rounded-xl shadow-md overflow-hidden p-6 transition-all duration-700 delay-300",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
              )}>
                <ProductOptions 
                  product={product}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  selectedSize={selectedSize}
                  setSelectedSize={setSelectedSize}
                  handleAddToCart={handleAddToCart}
                />
              </div>
            </div>
          </div>
          
          {/* Marketing and SEO Content */}
          <MarketingSection />
        </Container>
      </section>
    </MainLayout>
  );
};

export default CustomizeProduct;
