
import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import Container from '../ui-custom/Container';
import ProductCard from '../ui-custom/ProductCard';
import Button from '../ui-custom/Button';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

// Mock data
const featuredProducts = [
  {
    id: '1',
    title: 'Urban Gradient Tee',
    price: 34.99,
    imageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    isNew: true,
    isLimited: true,
  },
  {
    id: '2',
    title: 'Minimalist Line Art',
    price: 29.99,
    imageUrl: 'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
    isNew: false,
    isLimited: true,
  },
  {
    id: '3',
    title: 'Abstract Waves',
    price: 32.99,
    imageUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?q=80&w=1780&auto=format&fit=crop',
    isNew: true,
    isLimited: false,
  },
  {
    id: '4',
    title: 'Modern Typography',
    price: 27.99,
    imageUrl: 'https://images.unsplash.com/photo-1503341504253-dff4815485f1?q=80&w=1770&auto=format&fit=crop',
    isNew: false,
    isLimited: false,
  },
];

const FeaturedProducts = () => {
  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('featured-products');
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight * 0.75;
        setVisible(isVisible);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial state
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <section id="featured-products" className="py-20 bg-brand-light-gray overflow-hidden">
      <Container>
        <div className="flex flex-col items-center mb-12 text-center">
          <h2 className={cn(
            "text-3xl md:text-4xl font-bold mb-4 transition-all duration-700",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            Featured Collections
          </h2>
          <p className={cn(
            "max-w-2xl text-brand-dark-gray text-lg transition-all duration-700 delay-100",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            Discover our most popular designs, carefully crafted for those who appreciate unique style and premium quality.
          </p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          {featuredProducts.map((product, index) => (
            <div 
              key={product.id}
              className={cn(
                "transition-all duration-700",
                visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12",
                { 'delay-100': index === 0, 'delay-200': index === 1, 'delay-300': index === 2, 'delay-400': index === 3 }
              )}
            >
              <ProductCard {...product} />
            </div>
          ))}
        </div>
        
        <div className={cn(
          "flex justify-center transition-all duration-700 delay-500",
          visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
        )}>
          <Button as={Link} to="/shop" variant="default" size="lg">
            View All Products
            <ArrowRight className="ml-2 h-5 w-5" />
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default FeaturedProducts;
