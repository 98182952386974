
import { useEffect, useState } from 'react';
import Button from '../ui-custom/Button';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { cn } from '@/lib/utils';

const HeroSection = () => {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <section className="relative overflow-hidden bg-brand-black min-h-[90vh] flex items-center">
      {/* Background Abstract Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-20 -right-20 w-64 h-64 rounded-full bg-brand-orange/20 blur-3xl" />
        <div className="absolute top-1/2 -left-20 w-80 h-80 rounded-full bg-brand-orange/10 blur-3xl" />
      </div>
      
      {/* Content Container */}
      <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 sm:py-32">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <div className={cn(
            "transition-all duration-1000 delay-300",
            loaded ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-12"
          )}>
            <div className="inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-6">
              <p className="text-xs font-medium text-brand-orange">
                New Collection Available
              </p>
            </div>
            
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white leading-tight mb-6">
              Exclusive Limited-Edition
              <span className="text-brand-orange"> T-Shirt Designs</span>
            </h1>
            
            <p className="text-lg text-gray-400 mb-8 max-w-xl">
              Discover our collection of unique, premium quality t-shirts with designs that can't be found anywhere else. Each drop is limited and once sold out, they're gone forever.
            </p>
            
            <div className="flex flex-col sm:flex-row gap-4">
              <Button size="lg" as={Link} to="/shop">
                <span>Shop Collection</span>
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
              
              <Button 
                variant="outline" 
                size="lg" 
                as={Link} 
                to="/limited-drops"
                className="text-white border-white/20 hover:bg-white/5"
              >
                Limited Drops
              </Button>
            </div>
          </div>
          
          {/* Right Column - Image */}
          <div className={cn(
            "flex justify-center relative transition-all duration-1000 delay-500",
            loaded ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12"
          )}>
            <div className="relative overflow-hidden rounded-2xl bg-gradient-to-tr from-gray-900 to-brand-black w-full max-w-md aspect-[4/5] p-8">
              <div className="absolute inset-0 bg-grid-pattern opacity-20" />
              
              <img 
                src="/lovable-uploads/6ee030cf-ebc1-4fac-922d-e2abede81dd4.png" 
                alt="TheTshirtMart Premium T-shirt" 
                className="relative z-10 w-full h-full object-contain animate-float"
              />
              
              {/* Floating Badge */}
              <div className="absolute bottom-8 right-8 z-20 bg-brand-orange text-white rounded-full px-3 py-1 font-medium text-sm shadow-lg animate-pulse-slow">
                Limited Edition
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
