
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import ProductCard from '../components/ui-custom/ProductCard';
import Button from '../components/ui-custom/Button';

// Mock data
const limitedDrops = [
  {
    id: '1',
    title: 'Urban Gradient Tee',
    price: 34.99,
    imageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    isNew: true,
    isLimited: true,
    remaining: 7,
    total: 50,
  },
  {
    id: '2',
    title: 'Minimalist Line Art',
    price: 29.99,
    imageUrl: 'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
    isNew: false,
    isLimited: true,
    remaining: 12,
    total: 50,
  },
  {
    id: '6',
    title: 'Vintage Illustration',
    price: 33.99,
    imageUrl: 'https://images.unsplash.com/photo-1554568218-0f1715e72254?q=80&w=1887&auto=format&fit=crop',
    isNew: false,
    isLimited: true,
    remaining: 3,
    total: 25,
  },
  {
    id: '7',
    title: 'Artistic Portrait',
    price: 35.99,
    imageUrl: 'https://images.unsplash.com/photo-1586790170083-2f9cedef9ec2?q=80&w=1887&auto=format&fit=crop',
    isNew: true,
    isLimited: true,
    remaining: 21,
    total: 50,
  },
];

const LimitedDrops = () => {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    setLoaded(true);
  }, []);
  
  return (
    <MainLayout>
      <section className="bg-brand-black text-white py-16 sm:py-24">
        <Container>
          <div className="text-center max-w-3xl mx-auto mb-16">
            <span className="inline-block px-3 py-1 rounded-full bg-brand-orange/20 backdrop-blur-sm border border-brand-orange/30 mb-4">
              <p className="text-xs font-medium text-brand-orange">
                Limited Edition
              </p>
            </span>
            <h1 className={`text-4xl sm:text-5xl font-bold mb-6 transition-opacity duration-700 ${loaded ? 'opacity-100' : 'opacity-0'}`}>
              Exclusive Limited Drops
            </h1>
            <p className={`text-gray-400 text-lg transition-opacity duration-700 delay-200 ${loaded ? 'opacity-100' : 'opacity-0'}`}>
              These designs are produced in small batches, once they're gone, they're gone forever. Don't miss your chance to own a truly limited piece.
            </p>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
            {limitedDrops.map((product, index) => (
              <div 
                key={product.id} 
                className={`transition-all duration-700 ${loaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}
                style={{ transitionDelay: `${300 + index * 100}ms` }}
              >
                <ProductCard {...product} className="relative" />
                <div className="mt-3">
                  <div className="flex justify-between items-center text-sm text-gray-400 mb-1">
                    <span>Remaining</span>
                    <span>{product.remaining} of {product.total}</span>
                  </div>
                  <div className="h-1.5 w-full bg-gray-800 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-brand-orange rounded-full transition-all duration-1000"
                      style={{ 
                        width: `${(product.remaining / product.total) * 100}%`,
                        transitionDelay: `${500 + index * 100}ms`
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="text-center">
            <p className={`text-gray-400 mb-6 transition-opacity duration-700 delay-700 ${loaded ? 'opacity-100' : 'opacity-0'}`}>
              Want to be notified when we release new limited edition drops?
            </p>
            <div className={`transition-opacity duration-700 delay-800 ${loaded ? 'opacity-100' : 'opacity-0'}`}>
              <Button variant="premium" size="lg">
                Subscribe to Updates
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default LimitedDrops;
