
import { cn } from '@/lib/utils';
import { Mail, Phone, MapPin, MessageSquare, Clock } from 'lucide-react';

interface ContactInfoProps {
  visible: boolean;
}

const ContactInfo = ({ visible }: ContactInfoProps) => {
  return (
    <div className={cn(
      "lg:col-span-1 transition-all duration-700 delay-200",
      visible ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-8"
    )}>
      <div className="bg-white rounded-xl shadow-md overflow-hidden h-full">
        <div className="p-6 bg-brand-black text-white">
          <h2 className="text-xl font-bold mb-4">Contact Information</h2>
          <p className="text-gray-300">
            Reach out to us using any of the following methods, and we'll get back to you as soon as possible.
          </p>
        </div>
        
        <div className="p-6 space-y-6">
          <ContactInfoItem 
            icon={<Mail className="h-5 w-5 text-brand-orange" />}
            title="Email"
          >
            <a href="mailto:support@thetshirtmart.com" className="text-gray-700 hover:text-brand-orange transition-colors">
              support@thetshirtmart.com
            </a>
          </ContactInfoItem>
          
          <ContactInfoItem 
            icon={<Phone className="h-5 w-5 text-brand-orange" />}
            title="Phone"
          >
            <a href="tel:+1-555-123-4567" className="text-gray-700 hover:text-brand-orange transition-colors">
              +1 (555) 123-4567
            </a>
          </ContactInfoItem>
          
          <ContactInfoItem 
            icon={<MapPin className="h-5 w-5 text-brand-orange" />}
            title="Office Address"
          >
            <p className="text-gray-700">
              123 Design Street<br />
              Creative District<br />
              New York, NY 10001
            </p>
          </ContactInfoItem>
          
          <ContactInfoItem 
            icon={<MessageSquare className="h-5 w-5 text-brand-orange" />}
            title="Live Chat"
          >
            <p className="text-gray-700">
              Available on our website during business hours.
            </p>
          </ContactInfoItem>
          
          <ContactInfoItem 
            icon={<Clock className="h-5 w-5 text-brand-orange" />}
            title="Business Hours"
          >
            <p className="text-gray-700">
              Monday - Friday: 9:00 AM - 6:00 PM EST<br />
              Saturday: 10:00 AM - 4:00 PM EST<br />
              Sunday: Closed
            </p>
          </ContactInfoItem>
        </div>
      </div>
    </div>
  );
};

interface ContactInfoItemProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

const ContactInfoItem = ({ icon, title, children }: ContactInfoItemProps) => {
  return (
    <div className="flex items-start">
      <div className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center mr-4 flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="font-medium text-brand-black mb-1">{title}</h3>
        {children}
      </div>
    </div>
  );
};

export default ContactInfo;
