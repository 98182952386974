
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import ContactHeader from '../components/contact/ContactHeader';
import ContactInfo from '../components/contact/ContactInfo';
import ContactForm from '../components/contact/ContactForm';
import MapSection from '../components/contact/MapSection';

const Contact = () => {
  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  return (
    <MainLayout>
      <section className="py-20 bg-brand-light-gray">
        <Container>
          <ContactHeader visible={visible} />
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-16">
            <ContactInfo visible={visible} />
            <ContactForm visible={visible} />
          </div>
          
          <MapSection visible={visible} />
        </Container>
      </section>
    </MainLayout>
  );
};

export default Contact;
