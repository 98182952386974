
import { cn } from '@/lib/utils';

interface MapSectionProps {
  visible: boolean;
}

const MapSection = ({ visible }: MapSectionProps) => {
  return (
    <div className={cn(
      "bg-white rounded-xl shadow-md overflow-hidden transition-all duration-700 delay-400",
      visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
    )}>
      <div className="aspect-video w-full bg-gray-200 relative">
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <p className="text-gray-500 mb-2">Interactive map would be embedded here</p>
          <p className="text-sm text-gray-400">Google Maps or similar service integration</p>
        </div>
      </div>
    </div>
  );
};

export default MapSection;
