
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import Button from '../components/ui-custom/Button';
import { cn } from '@/lib/utils';
import { Star, Truck, RefreshCw, ShieldCheck, Heart, Share2, Ruler, Tag } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import TestimonialCard from '../components/testimonials/TestimonialCard';

// Mock product data
const products = [
  {
    id: '1',
    title: 'Urban Gradient Tee',
    price: 34.99,
    imageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    additionalImages: [
      'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
      'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
      'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?q=80&w=1780&auto=format&fit=crop',
    ],
    description: 'A bold, urban-inspired gradient design that transitions from vibrant orange to deep black. This exclusive t-shirt features premium cotton fabric and a relaxed fit that\'s perfect for everyday wear.',
    details: [
      'Premium 100% combed cotton (180 gsm)',
      'Pre-shrunk to minimize shrinkage',
      'Relaxed unisex fit',
      'Tear-away label for comfort',
      'Double-needle stitching for durability',
      'Printed with eco-friendly water-based inks'
    ],
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
    isNew: true,
    isLimited: true,
    inStock: true,
    remaining: 7,
    total: 50,
    relatedProducts: ['2', '3', '4'],
    rating: 4.8,
    reviewCount: 24,
    canCustomize: true
  },
  {
    id: '2',
    title: 'Minimalist Line Art',
    price: 29.99,
    imageUrl: 'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
    additionalImages: [
      'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
      'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
      'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?q=80&w=1780&auto=format&fit=crop',
    ],
    description: 'A sophisticated minimalist design featuring elegant line art. The clean, black lines on premium white fabric create a stylish contrast that pairs effortlessly with any outfit.',
    details: [
      'Premium 100% combed cotton (180 gsm)',
      'Pre-shrunk to minimize shrinkage',
      'Relaxed unisex fit',
      'Tear-away label for comfort',
      'Double-needle stitching for durability',
      'Printed with eco-friendly water-based inks'
    ],
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
    isNew: false,
    isLimited: true,
    inStock: true,
    remaining: 12,
    total: 50,
    relatedProducts: ['1', '3', '4'],
    rating: 4.7,
    reviewCount: 18,
    canCustomize: false
  },
];

// Mock related products data
const relatedProductsData = [
  {
    id: '1',
    title: 'Urban Gradient Tee',
    price: 34.99,
    imageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    isNew: true,
    isLimited: true,
  },
  {
    id: '2',
    title: 'Minimalist Line Art',
    price: 29.99,
    imageUrl: 'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
    isNew: false,
    isLimited: true,
  },
  {
    id: '3',
    title: 'Abstract Waves',
    price: 32.99,
    imageUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?q=80&w=1780&auto=format&fit=crop',
    isNew: true,
    isLimited: false,
  },
  {
    id: '4',
    title: 'Modern Typography',
    price: 27.99,
    imageUrl: 'https://images.unsplash.com/photo-1503341504253-dff4815485f1?q=80&w=1770&auto=format&fit=crop',
    isNew: false,
    isLimited: false,
  },
];

// Mock reviews
const reviews = [
  {
    id: '1',
    name: 'Alex Johnson',
    location: 'New York, NY',
    rating: 5,
    date: 'May 15, 2023',
    text: 'The quality of the fabric and print exceeded my expectations. This isn\'t your average t-shirt - the material is soft yet durable, and the design still looks amazing after several washes.',
    imageUrl: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=1587&auto=format&fit=crop',
    verified: true,
  },
  {
    id: '2',
    name: 'Sarah Miller',
    location: 'Austin, TX',
    rating: 5,
    date: 'June 2, 2023',
    text: 'I love that these designs are limited edition! I\'ve gotten so many compliments on my shirt, and it feels special knowing not everyone will have the same one. Will definitely be watching for the next drop!',
    imageUrl: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1588&auto=format&fit=crop',
    verified: true,
  },
];

const ProductDetail = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [inWishlist, setInWishlist] = useState(false);
  const { toast } = useToast();
  
  useEffect(() => {
    // Simulate API call to fetch product details
    const fetchProduct = () => {
      setLoading(true);
      
      // Find product by ID from mock data
      const foundProduct = products.find(p => p.id === productId);
      
      setTimeout(() => {
        setProduct(foundProduct || null);
        setLoading(false);
        if (foundProduct && foundProduct.sizes.length > 0) {
          setSelectedSize(foundProduct.sizes[0]);
        }
      }, 500);
    };
    
    fetchProduct();
  }, [productId]);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  const handleAddToCart = () => {
    if (!selectedSize) {
      toast({
        title: "Please select a size",
        description: "You need to select a size before adding to cart",
        variant: "destructive",
      });
      return;
    }
    
    toast({
      title: "Added to cart!",
      description: `${product.title} (${selectedSize}) has been added to your cart`,
      variant: "default",
    });
  };
  
  const handleToggleWishlist = () => {
    setInWishlist(!inWishlist);
    
    toast({
      title: inWishlist ? "Removed from wishlist" : "Added to wishlist",
      description: inWishlist ? `${product.title} has been removed from your wishlist` : `${product.title} has been added to your wishlist`,
      variant: "default",
    });
  };
  
  const handleShare = () => {
    // In a real app, this would open a share dialog
    toast({
      title: "Share this product",
      description: "Share link copied to clipboard!",
      variant: "default",
    });
  };
  
  if (loading) {
    return (
      <MainLayout>
        <Container>
          <div className="py-20 flex items-center justify-center">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-orange border-t-transparent" />
          </div>
        </Container>
      </MainLayout>
    );
  }
  
  if (!product) {
    return (
      <MainLayout>
        <Container>
          <div className="py-20 text-center">
            <h1 className="text-3xl font-bold mb-4">Product Not Found</h1>
            <p className="text-gray-600 mb-8">The product you're looking for doesn't exist or has been removed.</p>
            <Button as={Link} to="/shop">
              Return to Shop
            </Button>
          </div>
        </Container>
      </MainLayout>
    );
  }
  
  // Get related products data
  const relatedProducts = product.relatedProducts
    .map((id: string) => relatedProductsData.find(p => p.id === id))
    .filter(Boolean);
  
  return (
    <MainLayout>
      <section className="py-16">
        <Container>
          {/* Breadcrumb */}
          <div className={cn(
            "mb-8 transition-opacity duration-500",
            visible ? "opacity-100" : "opacity-0"
          )}>
            <div className="flex items-center text-sm text-gray-500">
              <Link to="/" className="hover:text-brand-orange">Home</Link>
              <span className="mx-2">/</span>
              <Link to="/shop" className="hover:text-brand-orange">Shop</Link>
              <span className="mx-2">/</span>
              <span className="text-brand-black">{product.title}</span>
            </div>
          </div>
          
          {/* Product Detail Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-16">
            {/* Product Images */}
            <div className={cn(
              "transition-all duration-700",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <div className="relative bg-gray-100 rounded-xl overflow-hidden aspect-square mb-4">
                <img
                  src={product.additionalImages[activeImageIndex]}
                  alt={product.title}
                  className="w-full h-full object-cover transition-opacity duration-300"
                />
                
                {/* Badges */}
                <div className="absolute top-4 left-4 flex flex-col gap-2">
                  {product.isNew && (
                    <span className="inline-flex h-6 items-center rounded-full bg-brand-orange px-2.5 text-xs font-medium text-white">
                      New
                    </span>
                  )}
                  {product.isLimited && (
                    <span className="inline-flex h-6 items-center rounded-full bg-black px-2.5 text-xs font-medium text-white">
                      Limited
                    </span>
                  )}
                </div>
              </div>
              
              {/* Image Thumbnails */}
              <div className="grid grid-cols-4 gap-2">
                {product.additionalImages.map((img: string, index: number) => (
                  <button
                    key={index}
                    onClick={() => setActiveImageIndex(index)}
                    className={cn(
                      "rounded-lg overflow-hidden aspect-square transition-all",
                      activeImageIndex === index ? "ring-2 ring-brand-orange" : "opacity-70 hover:opacity-100"
                    )}
                  >
                    <img
                      src={img}
                      alt={`${product.title} - View ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
            </div>
            
            {/* Product Info */}
            <div className={cn(
              "transition-all duration-700 delay-200",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h1 className="text-3xl sm:text-4xl font-bold text-brand-black mb-2">
                {product.title}
              </h1>
              
              <div className="flex items-center gap-2 mb-4">
                <div className="flex items-center">
                  {Array.from({ length: 5 }).map((_, i) => (
                    <Star
                      key={i}
                      size={16}
                      className={cn(
                        "fill-current",
                        i < Math.floor(product.rating) ? "text-yellow-400" : "text-gray-300"
                      )}
                    />
                  ))}
                </div>
                <span className="text-sm text-gray-500">
                  {product.rating} ({product.reviewCount} reviews)
                </span>
              </div>
              
              <p className="text-2xl font-bold text-brand-orange mb-6">
                ${product.price.toFixed(2)}
              </p>
              
              <p className="text-gray-700 mb-6">
                {product.description}
              </p>
              
              {/* Product Details */}
              <div className="mb-6">
                <h3 className="font-medium text-brand-black mb-2">Details</h3>
                <ul className="text-gray-700 space-y-1">
                  {product.details.map((detail: string, index: number) => (
                    <li key={index} className="flex items-start">
                      <span className="text-brand-orange mr-2">•</span>
                      <span>{detail}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              {/* Remaining Stock */}
              {product.isLimited && (
                <div className="mb-6">
                  <div className="flex justify-between items-center text-sm text-gray-500 mb-1">
                    <span>Limited Edition</span>
                    <span>{product.remaining} of {product.total} remaining</span>
                  </div>
                  <div className="h-1.5 w-full bg-gray-100 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-brand-orange rounded-full transition-all duration-1000"
                      style={{ width: `${(product.remaining / product.total) * 100}%` }}
                    ></div>
                  </div>
                </div>
              )}
              
              {/* Size Selection */}
              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-brand-black">Size</h3>
                  <button 
                    className="flex items-center text-sm text-gray-500 hover:text-brand-orange transition-colors"
                    onClick={() => {
                      toast({
                        title: "Size Guide",
                        description: "Size guide opened in a new tab",
                      });
                    }}
                  >
                    <Ruler size={16} className="mr-1" />
                    Size Guide
                  </button>
                </div>
                
                <div className="flex flex-wrap gap-2">
                  {product.sizes.map((size: string) => (
                    <button
                      key={size}
                      onClick={() => setSelectedSize(size)}
                      className={cn(
                        "h-10 w-10 rounded-md flex items-center justify-center border text-sm font-medium transition-all",
                        selectedSize === size
                          ? "border-brand-orange bg-brand-orange/10 text-brand-orange"
                          : "border-gray-300 hover:border-gray-400 text-gray-700"
                      )}
                    >
                      {size}
                    </button>
                  ))}
                </div>
              </div>
              
              {/* Quantity */}
              <div className="mb-6">
                <h3 className="font-medium text-brand-black mb-2">Quantity</h3>
                <div className="flex items-center">
                  <button
                    onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                    className="h-10 w-10 flex items-center justify-center border border-gray-300 rounded-l-md text-gray-600 hover:bg-gray-100 transition-colors"
                  >
                    -
                  </button>
                  <div className="h-10 w-14 flex items-center justify-center border-t border-b border-gray-300 text-center">
                    {quantity}
                  </div>
                  <button
                    onClick={() => setQuantity(quantity + 1)}
                    className="h-10 w-10 flex items-center justify-center border border-gray-300 rounded-r-md text-gray-600 hover:bg-gray-100 transition-colors"
                  >
                    +
                  </button>
                </div>
              </div>
              
              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 mb-8">
                <Button 
                  onClick={handleAddToCart} 
                  size="lg" 
                  className="flex-1"
                >
                  Add to Cart
                </Button>
                
                {product.canCustomize && (
                  <Button 
                    as={Link} 
                    to={`/customize/${product.id}`} 
                    variant="outline" 
                    size="lg" 
                    className="flex-1"
                  >
                    Customize This Design
                  </Button>
                )}
                
                <Button
                  onClick={handleToggleWishlist}
                  variant="ghost"
                  size="lg"
                  className={cn(
                    "w-14 flex-shrink-0 p-0",
                    inWishlist && "text-red-500"
                  )}
                  aria-label="Add to wishlist"
                >
                  <Heart className={cn("h-6 w-6", inWishlist && "fill-current")} />
                </Button>
                
                <Button
                  onClick={handleShare}
                  variant="ghost"
                  size="lg"
                  className="w-14 flex-shrink-0 p-0"
                  aria-label="Share product"
                >
                  <Share2 className="h-6 w-6" />
                </Button>
              </div>
              
              {/* Product Categories */}
              <div className="flex items-center text-sm text-gray-500">
                <Tag size={16} className="mr-2" />
                <span className="mr-2">Categories:</span>
                <Link to="/shop?category=limited-edition" className="text-brand-orange hover:underline mr-2">
                  Limited Edition
                </Link>
                <Link to="/shop?category=unisex" className="text-brand-orange hover:underline">
                  Unisex
                </Link>
              </div>
            </div>
          </div>
          
          {/* Features */}
          <div className={cn(
            "grid grid-cols-1 md:grid-cols-3 gap-6 mb-16 transition-all duration-700 delay-300",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <div className="flex items-center p-4 border border-gray-200 rounded-lg">
              <div className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center mr-4">
                <Truck className="h-5 w-5 text-brand-orange" />
              </div>
              <div>
                <h3 className="font-medium text-brand-black">Free Shipping</h3>
                <p className="text-sm text-gray-500">On orders over $50</p>
              </div>
            </div>
            
            <div className="flex items-center p-4 border border-gray-200 rounded-lg">
              <div className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center mr-4">
                <RefreshCw className="h-5 w-5 text-brand-orange" />
              </div>
              <div>
                <h3 className="font-medium text-brand-black">Easy Returns</h3>
                <p className="text-sm text-gray-500">30-day return policy</p>
              </div>
            </div>
            
            <div className="flex items-center p-4 border border-gray-200 rounded-lg">
              <div className="w-10 h-10 rounded-full bg-brand-orange/10 flex items-center justify-center mr-4">
                <ShieldCheck className="h-5 w-5 text-brand-orange" />
              </div>
              <div>
                <h3 className="font-medium text-brand-black">Secure Checkout</h3>
                <p className="text-sm text-gray-500">Protected payments</p>
              </div>
            </div>
          </div>
          
          {/* Reviews Section */}
          <div className={cn(
            "mb-16 transition-all duration-700 delay-400",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <div className="flex items-center justify-between mb-8">
              <h2 className="text-2xl font-bold text-brand-black">Customer Reviews</h2>
              <Button variant="outline" size="sm">Write a Review</Button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {reviews.map((review) => (
                <TestimonialCard key={review.id} {...review} />
              ))}
            </div>
            
            <div className="text-center">
              <Button variant="ghost">
                View All {product.reviewCount} Reviews
              </Button>
            </div>
          </div>
          
          {/* Related Products */}
          <div className={cn(
            "transition-all duration-700 delay-500",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <h2 className="text-2xl font-bold text-brand-black mb-8">You Might Also Like</h2>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {relatedProducts.map((relatedProduct: any) => (
                <Link 
                  key={relatedProduct.id} 
                  to={`/products/${relatedProduct.id}`}
                  className="block group"
                >
                  <div className="relative bg-gray-100 rounded-xl overflow-hidden aspect-square mb-4">
                    <img
                      src={relatedProduct.imageUrl}
                      alt={relatedProduct.title}
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    
                    {/* Badges */}
                    <div className="absolute top-4 left-4 flex flex-col gap-2">
                      {relatedProduct.isNew && (
                        <span className="inline-flex h-6 items-center rounded-full bg-brand-orange px-2.5 text-xs font-medium text-white">
                          New
                        </span>
                      )}
                      {relatedProduct.isLimited && (
                        <span className="inline-flex h-6 items-center rounded-full bg-black px-2.5 text-xs font-medium text-white">
                          Limited
                        </span>
                      )}
                    </div>
                  </div>
                  
                  <h3 className="font-medium text-brand-black group-hover:text-brand-orange transition-colors">
                    {relatedProduct.title}
                  </h3>
                  
                  <p className="font-semibold text-brand-orange">
                    ${relatedProduct.price.toFixed(2)}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default ProductDetail;
