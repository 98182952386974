
import { useState } from 'react';
import { Type, ImageIcon, Save } from 'lucide-react';
import { cn } from '@/lib/utils';
import Button from '@/components/ui-custom/Button';
import TextCustomization from './TextCustomization';
import ImageCustomization from './ImageCustomization';

interface CustomizationControlsProps {
  product: any;
  visible: boolean;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  customText: string;
  setCustomText: (text: string) => void;
  textColor: string;
  setTextColor: (color: string) => void;
  textSize: number;
  setTextSize: (size: number) => void;
  textFont: string;
  setTextFont: (font: string) => void;
  textRotation: number;
  setTextRotation: (rotation: number) => void;
  uploadedImage: string | null;
  setUploadedImage: (image: string | null) => void;
  imageScale: number;
  setImageScale: (scale: number) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearCustomization: () => void;
  onSaveDesign: () => void;
}

const CustomizationControls = ({
  product,
  visible,
  activeTab,
  setActiveTab,
  customText,
  setCustomText,
  textColor,
  setTextColor,
  textSize,
  setTextSize,
  textFont,
  setTextFont,
  textRotation,
  setTextRotation,
  uploadedImage,
  setUploadedImage,
  imageScale,
  setImageScale,
  fileInputRef,
  handleImageUpload,
  clearCustomization,
  onSaveDesign,
}: CustomizationControlsProps) => {
  return (
    <div className={cn(
      "transition-all duration-700 delay-200",
      visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
    )}>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-brand-black mb-2">
            Customize Your Design
          </h2>
          <p className="text-gray-600 mb-6">
            Create something amazing with our easy-to-use design tools
          </p>
          
          {/* Tabs */}
          <div className="flex mb-6 border-b">
            <button
              onClick={() => setActiveTab('text')}
              className={cn(
                "flex items-center py-3 px-4 font-medium border-b-2 -mb-px transition-colors",
                activeTab === 'text'
                  ? "border-brand-orange text-brand-orange"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              )}
            >
              <Type size={18} className="mr-2" />
              Add Text
            </button>
            
            <button
              onClick={() => setActiveTab('image')}
              className={cn(
                "flex items-center py-3 px-4 font-medium border-b-2 -mb-px transition-colors",
                activeTab === 'image'
                  ? "border-brand-orange text-brand-orange"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              )}
            >
              <ImageIcon size={18} className="mr-2" />
              Upload Image
            </button>
          </div>
          
          {/* Text Customization */}
          {activeTab === 'text' && (
            <TextCustomization
              customText={customText}
              setCustomText={setCustomText}
              textColor={textColor}
              setTextColor={setTextColor}
              textSize={textSize}
              setTextSize={setTextSize}
              textFont={textFont}
              setTextFont={setTextFont}
              textRotation={textRotation}
              setTextRotation={setTextRotation}
              availableFonts={product.availableFonts}
            />
          )}
          
          {/* Image Customization */}
          {activeTab === 'image' && (
            <ImageCustomization
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              imageScale={imageScale}
              setImageScale={setImageScale}
              textRotation={textRotation}
              setTextRotation={setTextRotation}
              handleImageUpload={handleImageUpload}
              fileInputRef={fileInputRef}
            />
          )}
          
          {/* Actions */}
          <div className="mt-8 flex items-center justify-between">
            <Button 
              onClick={clearCustomization}
              variant="ghost"
              className="text-gray-700"
            >
              Clear
            </Button>
            
            <Button 
              onClick={onSaveDesign}
              variant="outline"
            >
              <Save size={18} className="mr-2" />
              Save Design
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizationControls;
