
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import { cn } from '@/lib/utils';
import BlogCard from '../components/blog/BlogCard';
import Button from '../components/ui-custom/Button';
import { Search } from 'lucide-react';

// Mock blog posts data
const blogPosts = [
  {
    slug: 'the-art-of-t-shirt-design',
    title: 'The Art of T-Shirt Design: From Concept to Print',
    excerpt: 'Discover the creative process behind our limited-edition t-shirt designs, from initial concept sketches to final printing techniques.',
    coverImage: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?q=80&w=1471&auto=format&fit=crop',
    date: 'October 15, 2023',
    author: {
      name: 'Jason Palmer',
      avatar: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?q=80&w=1471&auto=format&fit=crop',
    },
    category: 'Design Process',
    featured: true,
  },
  {
    slug: 'sustainable-fashion-practices',
    title: 'How Print-on-Demand is Revolutionizing Sustainable Fashion',
    excerpt: 'Learn how print-on-demand technology is reducing waste in the fashion industry and why it\'s the future of sustainable clothing production.',
    coverImage: 'https://images.unsplash.com/photo-1532453288672-3a27e9be9efd?q=80&w=1664&auto=format&fit=crop',
    date: 'September 28, 2023',
    author: {
      name: 'Emma Reynolds',
      avatar: 'https://images.unsplash.com/photo-1548142813-c348350df52b?q=80&w=1589&auto=format&fit=crop',
    },
    category: 'Sustainability',
  },
  {
    slug: 'styling-graphic-tees',
    title: '10 Ways to Style Graphic Tees for Any Occasion',
    excerpt: 'From casual outings to dressier events, discover versatile ways to incorporate your favorite graphic tees into stylish, head-turning outfits.',
    coverImage: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    date: 'September 15, 2023',
    author: {
      name: 'Alex Johnson',
    },
    category: 'Fashion Tips',
  },
  {
    slug: 'history-of-graphic-tees',
    title: 'The Evolution of Graphic Tees: From Counterculture to Mainstream Fashion',
    excerpt: 'Explore the fascinating history of graphic t-shirts, from their origins as symbols of rebellion to their current status as fashion staples.',
    coverImage: 'https://images.unsplash.com/photo-1503342217505-b0a15ec3261c?q=80&w=1470&auto=format&fit=crop',
    date: 'August 20, 2023',
    author: {
      name: 'Michael Chen',
    },
    category: 'Fashion History',
  },
  {
    slug: 'interview-with-designer',
    title: 'Behind the Design: Interview with Streetwear Artist Maya Lin',
    excerpt: 'Meet Maya Lin, the talented artist behind our popular Urban Gradient collection, as she discusses her inspiration and creative process.',
    coverImage: 'https://images.unsplash.com/photo-1544441892-794166f1e3be?q=80&w=1470&auto=format&fit=crop',
    date: 'August 5, 2023',
    author: {
      name: 'Jason Palmer',
      avatar: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?q=80&w=1471&auto=format&fit=crop',
    },
    category: 'Designer Spotlight',
  },
  {
    slug: 'limited-edition-collecting',
    title: 'The Rise of Limited Edition Apparel Collecting',
    excerpt: 'Discover why limited edition t-shirts are becoming coveted collectibles and how to start your own collection of rare, exclusive designs.',
    coverImage: 'https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?q=80&w=1470&auto=format&fit=crop',
    date: 'July 22, 2023',
    author: {
      name: 'Sarah Miller',
      avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1588&auto=format&fit=crop',
    },
    category: 'Trends',
  },
  {
    slug: 'care-guide-for-tshirts',
    title: 'Ultimate Care Guide: Making Your Graphic Tees Last Longer',
    excerpt: 'Learn the best practices for washing, drying, and storing your favorite t-shirts to preserve their print quality and extend their lifespan.',
    coverImage: 'https://images.unsplash.com/photo-1582655432787-e6b8cd2cfb76?q=80&w=1480&auto=format&fit=crop',
    date: 'July 10, 2023',
    author: {
      name: 'Emma Reynolds',
      avatar: 'https://images.unsplash.com/photo-1548142813-c348350df52b?q=80&w=1589&auto=format&fit=crop',
    },
    category: 'Care Tips',
  },
];

// Categories
const categories = [
  'All',
  'Design Process',
  'Sustainability',
  'Fashion Tips',
  'Fashion History',
  'Designer Spotlight',
  'Trends',
  'Care Tips',
];

const Blog = () => {
  const [visible, setVisible] = useState(false);
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPosts, setFilteredPosts] = useState(blogPosts);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, []);
  
  useEffect(() => {
    // Filter posts based on category and search query
    let filtered = blogPosts;
    
    if (activeCategory !== 'All') {
      filtered = filtered.filter(post => post.category === activeCategory);
    }
    
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(post => 
        post.title.toLowerCase().includes(query) || 
        post.excerpt.toLowerCase().includes(query)
      );
    }
    
    setFilteredPosts(filtered);
  }, [activeCategory, searchQuery]);
  
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // The filtering is already handled in the useEffect
  };
  
  return (
    <MainLayout>
      <section className="py-20 bg-brand-light-gray">
        <Container>
          <div className="text-center mb-12">
            <span className={cn(
              "inline-block px-3 py-1 rounded-full bg-brand-orange/10 backdrop-blur-sm border border-brand-orange/20 mb-4 transition-opacity duration-700",
              visible ? "opacity-100" : "opacity-0"
            )}>
              <p className="text-xs font-medium text-brand-orange">
                Fashion Insights & Inspirations
              </p>
            </span>
            
            <h1 className={cn(
              "text-4xl md:text-5xl font-bold mb-6 transition-all duration-700",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              The T-Shirt Blog
            </h1>
            
            <p className={cn(
              "max-w-2xl mx-auto text-brand-dark-gray text-lg transition-all duration-700 delay-100",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              Explore the latest trends, design inspiration, styling tips, and behind-the-scenes stories from the world of t-shirt design.
            </p>
          </div>
          
          {/* Search and Filter */}
          <div className={cn(
            "flex flex-col md:flex-row items-stretch md:items-center justify-between gap-4 mb-12 transition-all duration-700 delay-200",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            {/* Categories */}
            <div className="overflow-x-auto pb-2 md:pb-0 flex-grow">
              <div className="flex items-center space-x-2">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => setActiveCategory(category)}
                    className={cn(
                      "px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors",
                      activeCategory === category
                        ? "bg-brand-orange text-white"
                        : "bg-white text-gray-700 hover:bg-gray-100"
                    )}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Search */}
            <form onSubmit={handleSearch} className="flex-shrink-0 w-full md:w-64 relative">
              <input
                type="text"
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 pl-10 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange transition-all text-sm"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
            </form>
          </div>
          
          {/* Blog Posts Grid */}
          {filteredPosts.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
              {filteredPosts.map((post, index) => (
                <div
                  key={post.slug}
                  className={cn(
                    "transition-all duration-700",
                    visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8",
                    { 'delay-300': index === 0, 'delay-400': index === 1, 'delay-500': index === 2, 'delay-600': index > 2 },
                    post.featured ? "lg:col-span-2" : ""
                  )}
                >
                  <BlogCard {...post} />
                </div>
              ))}
            </div>
          ) : (
            <div className={cn(
              "text-center py-16 transition-all duration-700 delay-300",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <h3 className="text-xl font-bold text-brand-black mb-2">No articles found</h3>
              <p className="text-gray-600 mb-6">
                Try adjusting your search or filter to find what you're looking for.
              </p>
              <Button onClick={() => { setActiveCategory('All'); setSearchQuery(''); }}>
                Reset Filters
              </Button>
            </div>
          )}
          
          {filteredPosts.length > 0 && (
            <div className={cn(
              "text-center transition-all duration-700 delay-600",
              visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            )}>
              <Button variant="outline" size="lg">
                Load More Articles
              </Button>
            </div>
          )}
          
          {/* Newsletter */}
          <div className={cn(
            "mt-20 bg-white rounded-xl shadow-lg p-8 transition-all duration-700 delay-700",
            visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          )}>
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-2xl font-bold text-brand-black mb-4">
                Subscribe to Our Newsletter
              </h2>
              
              <p className="text-gray-700 mb-6">
                Stay updated with the latest blog posts, design trends, and exclusive offers delivered directly to your inbox.
              </p>
              
              <form className="flex flex-col sm:flex-row gap-3 max-w-lg mx-auto">
                <input
                  type="email"
                  placeholder="Enter your email"
                  required
                  className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-orange/50 focus:border-brand-orange flex-grow"
                />
                <Button type="submit">
                  Subscribe
                </Button>
              </form>
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default Blog;
