
import { useRef } from 'react';
import { X, ImageIcon, Minus, Plus, RotateCw } from 'lucide-react';

interface ImageCustomizationProps {
  uploadedImage: string | null;
  setUploadedImage: (image: string | null) => void;
  imageScale: number;
  setImageScale: (scale: number) => void;
  textRotation: number;
  setTextRotation: (rotation: number) => void;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
}

const ImageCustomization = ({
  uploadedImage,
  setUploadedImage,
  imageScale,
  setImageScale,
  textRotation,
  setTextRotation,
  handleImageUpload,
  fileInputRef,
}: ImageCustomizationProps) => {
  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Upload Image
        </label>
        
        {!uploadedImage ? (
          <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
              id="image-upload"
            />
            <label
              htmlFor="image-upload"
              className="flex flex-col items-center justify-center cursor-pointer"
            >
              <ImageIcon size={48} className="text-gray-400 mb-3" />
              <span className="text-gray-700 font-medium mb-1">Click to upload</span>
              <span className="text-sm text-gray-500">PNG, JPG, GIF up to 5MB</span>
            </label>
          </div>
        ) : (
          <div className="border border-gray-300 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm text-gray-700">Image uploaded successfully</span>
              <button
                onClick={() => {
                  setUploadedImage(null);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                }}
                className="text-red-500 hover:text-red-700 transition-colors"
              >
                <X size={18} />
              </button>
            </div>
            
            <div className="aspect-square bg-gray-100 rounded overflow-hidden flex items-center justify-center">
              <img
                src={uploadedImage}
                alt="Uploaded"
                className="max-w-full max-h-full object-contain"
              />
            </div>
          </div>
        )}
        <p className="mt-1 text-xs text-gray-500">
          Upload your logo, artwork, or any image to create a truly personalized design.
        </p>
      </div>
      
      {uploadedImage && (
        <>
          <div>
            <label htmlFor="image-scale" className="block text-sm font-medium text-gray-700 mb-1">
              Image Size
            </label>
            <div className="flex items-center gap-3">
              <button
                onClick={() => setImageScale(Math.max(10, imageScale - 10))}
                className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
              >
                <Minus size={16} />
              </button>
              
              <input
                id="image-scale"
                type="range"
                min="10"
                max="200"
                value={imageScale}
                onChange={(e) => setImageScale(Number(e.target.value))}
                className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              
              <button
                onClick={() => setImageScale(Math.min(200, imageScale + 10))}
                className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
              >
                <Plus size={16} />
              </button>
              
              <span className="text-sm text-gray-600 w-12 text-center">{imageScale}%</span>
            </div>
          </div>
          
          <div>
            <label htmlFor="image-rotation" className="block text-sm font-medium text-gray-700 mb-1">
              Rotation
            </label>
            <div className="flex items-center gap-3">
              <button
                onClick={() => {
                  const newRotation = ((textRotation - 15) % 360);
                  setTextRotation(newRotation);
                }}
                className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
              >
                <RotateCw size={16} className="transform -scale-x-100" />
              </button>
              
              <input
                id="image-rotation"
                type="range"
                min="-180"
                max="180"
                value={textRotation}
                onChange={(e) => setTextRotation(Number(e.target.value))}
                className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              
              <button
                onClick={() => {
                  const newRotation = ((textRotation + 15) % 360);
                  setTextRotation(newRotation);
                }}
                className="h-8 w-8 flex items-center justify-center border border-gray-300 rounded text-gray-600 hover:bg-gray-100 transition-colors"
              >
                <RotateCw size={16} />
              </button>
              
              <span className="text-sm text-gray-600 w-10 text-center">{textRotation}°</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCustomization;
