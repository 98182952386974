
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { CalendarDays, User } from 'lucide-react';

interface BlogCardProps {
  slug: string;
  title: string;
  excerpt: string;
  coverImage: string;
  date: string;
  author: {
    name: string;
    avatar?: string;
  };
  category: string;
  featured?: boolean;
}

const BlogCard = ({
  slug,
  title,
  excerpt,
  coverImage,
  date,
  author,
  category,
  featured = false,
}: BlogCardProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  
  useEffect(() => {
    const img = new Image();
    img.src = coverImage;
    img.onload = () => setIsImageLoaded(true);
  }, [coverImage]);

  return (
    <Link 
      to={`/blog/${slug}`} 
      className={cn(
        "group block bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300",
        featured ? "lg:col-span-2" : ""
      )}
    >
      <div className={cn(
        "relative aspect-[16/9] overflow-hidden bg-gray-100",
        featured ? "lg:aspect-[2/1]" : ""
      )}>
        {!isImageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-orange border-t-transparent" />
          </div>
        )}
        
        <img
          src={coverImage}
          alt={title}
          className={cn(
            "h-full w-full object-cover transition-transform duration-500",
            isImageLoaded ? "opacity-100" : "opacity-0",
            "group-hover:scale-105"
          )}
        />
        
        <div className="absolute top-4 left-4">
          <span className="inline-block px-3 py-1 rounded-full bg-brand-orange/80 backdrop-blur-sm text-white text-xs font-medium">
            {category}
          </span>
        </div>
      </div>
      
      <div className="p-6">
        <h3 className={cn(
          "font-bold text-brand-black mb-3 group-hover:text-brand-orange transition-colors line-clamp-2",
          featured ? "text-xl md:text-2xl" : "text-lg"
        )}>
          {title}
        </h3>
        
        <p className="text-gray-600 mb-4 line-clamp-2">
          {excerpt}
        </p>
        
        <div className="flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            {author.avatar ? (
              <img
                src={author.avatar}
                alt={author.name}
                className="w-6 h-6 rounded-full mr-2"
              />
            ) : (
              <User size={16} className="mr-2" />
            )}
            <span>{author.name}</span>
          </div>
          
          <div className="flex items-center">
            <CalendarDays size={16} className="mr-2" />
            <span>{date}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
