
import { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Container from '../components/ui-custom/Container';
import ProductCard from '../components/ui-custom/ProductCard';
import { Filter } from 'lucide-react';

// Mock data
const products = [
  {
    id: '1',
    title: 'Urban Gradient Tee',
    price: 34.99,
    imageUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?q=80&w=1664&auto=format&fit=crop',
    isNew: true,
    isLimited: true,
  },
  {
    id: '2',
    title: 'Minimalist Line Art',
    price: 29.99,
    imageUrl: 'https://images.unsplash.com/photo-1529374255404-311a2a4f1fd9?q=80&w=1769&auto=format&fit=crop',
    isNew: false,
    isLimited: true,
  },
  {
    id: '3',
    title: 'Abstract Waves',
    price: 32.99,
    imageUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?q=80&w=1780&auto=format&fit=crop',
    isNew: true,
    isLimited: false,
  },
  {
    id: '4',
    title: 'Modern Typography',
    price: 27.99,
    imageUrl: 'https://images.unsplash.com/photo-1503341504253-dff4815485f1?q=80&w=1770&auto=format&fit=crop',
    isNew: false,
    isLimited: false,
  },
  {
    id: '5',
    title: 'Geometric Patterns',
    price: 31.99,
    imageUrl: 'https://images.unsplash.com/photo-1556821840-3a63f95609a7?q=80&w=1887&auto=format&fit=crop',
    isNew: true,
    isLimited: false,
  },
  {
    id: '6',
    title: 'Vintage Illustration',
    price: 33.99,
    imageUrl: 'https://images.unsplash.com/photo-1554568218-0f1715e72254?q=80&w=1887&auto=format&fit=crop',
    isNew: false,
    isLimited: true,
  },
  {
    id: '7',
    title: 'Artistic Portrait',
    price: 35.99,
    imageUrl: 'https://images.unsplash.com/photo-1586790170083-2f9cedef9ec2?q=80&w=1887&auto=format&fit=crop',
    isNew: true,
    isLimited: true,
  },
  {
    id: '8',
    title: 'Monochrome Design',
    price: 28.99,
    imageUrl: 'https://images.unsplash.com/photo-1618517351616-38fb9c5210c6?q=80&w=1887&auto=format&fit=crop',
    isNew: false,
    isLimited: false,
  },
];

const Shop = () => {
  const [loaded, setLoaded] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  
  useEffect(() => {
    setLoaded(true);
  }, []);
  
  return (
    <MainLayout>
      <section className="py-12 sm:py-16">
        <Container>
          {/* Page Header */}
          <div className="mb-8 sm:mb-12">
            <h1 className={`text-3xl sm:text-4xl font-bold mb-4 transition-opacity duration-700 ${loaded ? 'opacity-100' : 'opacity-0'}`}>
              Shop All T-Shirts
            </h1>
            <div className={`flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 transition-opacity duration-700 delay-200 ${loaded ? 'opacity-100' : 'opacity-0'}`}>
              <p className="text-brand-gray">
                Showing all {products.length} results
              </p>
              
              <button 
                className="flex items-center gap-2 text-sm font-medium sm:ml-auto"
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                <Filter size={16} />
                <span>Filters</span>
              </button>
            </div>
          </div>
          
          {/* Filters (hidden by default on mobile) */}
          <div className={`overflow-hidden transition-all duration-300 mb-6 ${filtersOpen ? 'max-h-96' : 'max-h-0 sm:max-h-96'}`}>
            <div className="p-4 bg-brand-light-gray rounded-lg mb-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                  <h3 className="font-medium mb-2">Collection</h3>
                  <ul className="space-y-1">
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>All Designs</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>Limited Edition</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>New Arrivals</span>
                      </label>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="font-medium mb-2">Size</h3>
                  <ul className="space-y-1">
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>S</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>M</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>L</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>XL</span>
                      </label>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="font-medium mb-2">Price</h3>
                  <ul className="space-y-1">
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>Under $30</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>$30 - $35</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input type="checkbox" className="rounded border-gray-300" />
                        <span>Over $35</span>
                      </label>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="font-medium mb-2">Sort By</h3>
                  <select className="w-full rounded-md border-gray-300 focus:border-brand-orange focus:ring focus:ring-brand-orange/20">
                    <option>Newest</option>
                    <option>Price: Low to High</option>
                    <option>Price: High to Low</option>
                    <option>Popularity</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          
          {/* Products Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {products.map((product, index) => (
              <div 
                key={product.id} 
                className={`transition-all duration-700 ${loaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}
                style={{ transitionDelay: `${200 + index * 50}ms` }}
              >
                <ProductCard {...product} />
              </div>
            ))}
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default Shop;
